.mobile-booking-card {
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.mobile-booking-button {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.icon-circle {
  position: absolute;
  left: 12px;
  z-index: 2;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 1.5px solid #10b2e1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle svg {
  width: 22px;
  height: 22px;
  color: #10b2e1;
}

/* For SOS button */
.mobile-booking-button:has(button[type='danger']) .icon-circle {
  border-color: #e91e63;
}

.mobile-booking-button:has(button[type='danger']) .icon-circle svg {
  color: #e91e63;
}

.mobile-booking-button button {
  width: 100%;
  padding-left: 64px !important;
}

/* Adjust text positioning */
.mobile-booking-button span {
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
}

/* Container styles */
.mobile-booking-button-container {
  display: flex;
  gap: 16px;
  padding: 0 16px;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mobile-booking-button-container::-webkit-scrollbar {
  display: none;
}

/* Hover effect */
.mobile-booking-button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

/* Active state */
.mobile-booking-button:active {
  transform: translateY(1px);
}

/* Disabled state */
.mobile-booking-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Fixed booking container */
.mobile-booking-shortcut {
  width: 100%;
  padding: 16px;
  position: relative;
  z-index: 99;
}

/* Add or update these styles */
.mobile-booking-button .anticon {
  background: rgba(255, 255, 255, 0.9);
  width: 48px !important; /* Increased from 40px */
  height: 48px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px !important;
}

.mobile-booking-button .anticon svg {
  width: 28px; /* Increased icon size */
  height: 28px;
  color: #2196f3; /* Match button gradient color */
}

/* For SOS button */
.mobile-booking-button[type='danger'] .anticon {
  background: rgba(255, 255, 255, 0.9);
}

.mobile-booking-button[type='danger'] .anticon svg {
  color: #e91e63; /* Match SOS button color */
}

/* Hover effects to match other buttons */
.mobile-booking-button button:hover {
  background: rgba(16, 178, 225, 0.2) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

.mobile-booking-button button[type='danger']:hover {
  background: rgba(233, 30, 99, 0.2) !important;
  border-color: #e91e63 !important;
  color: #e91e63 !important;
}

/* Hover effects */
.mobile-booking-button button:hover .icon-circle {
  border-color: #10b2e1;
  opacity: 0.9;
}

.mobile-booking-button button[type='danger']:hover .icon-circle {
  border-color: #e91e63;
  opacity: 0.9;
}

/* Add a subtle shadow to indicate fixed position */
.booking-shortcuts-section::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent);
  pointer-events: none;
}
