.timeframe-selector {
  padding: 16px;
  border-top: 1px solid rgba(16, 178, 225, 0.15);
  margin-top: 16px;
}

.timeframe-selector .timeframe-controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.timeframe-selector .timeframe-tabs {
  flex: 1;
  display: flex;
  background: #141824;
  padding: 4px;
  border-radius: 12px;
  border: 1px solid rgba(16, 178, 225, 0.15);
}

.timeframe-selector .timeframe-tab-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 36px;
}

.timeframe-selector .timeframe-tips-button {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  background: #141824 !important;
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 12px !important;
  color: rgba(255, 255, 255, 0.5) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: all 0.2s ease !important;
}

.timeframe-selector .timeframe-tips-button svg {
  font-size: 16px;
  color: #10b2e1;
}

.timeframe-selector .timeframe-tips-button:hover {
  background: rgba(16, 178, 225, 0.05) !important;
  transform: translateY(-1px);
  border-color: rgba(16, 178, 225, 0.3) !important;
  box-shadow: 0 4px 12px rgba(16, 178, 225, 0.1);
}

.timeframe-selector .timeframe-tab-button:hover:not(.selector-button-active) {
  background: rgba(16, 178, 225, 0.05);
  color: rgba(255, 255, 255, 0.8);
}

.timeframe-selector .timeframe-tab-button.selector-button-active {
  background: rgba(16, 178, 225, 0.25) !important;
  color: #10b2e1 !important;
  font-weight: 600;
  border: 1px solid rgba(16, 178, 225, 0.5);
  box-shadow: 0 0 16px rgba(16, 178, 225, 0.4),
              inset 0 0 8px rgba(16, 178, 225, 0.3) !important;
}

.timeframe-selector .timeframe-tab-button.selector-button-active svg {
  color: #10b2e1;
  filter: drop-shadow(0 0 10px rgba(16, 178, 225, 0.6));
}

.timeframe-selector .timeframe-tab-button svg {
  font-size: 14px;
  transition: all 0.2s ease;
} 