.test-detail-warning {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  background: rgba(255, 159, 122, 0.1);
  border-radius: 12px;
  margin-top: 32px;
}

.test-detail-warning svg {
  color: #FF9F7A;
  flex-shrink: 0;
  margin-top: 4px;
}

.test-detail-warning .ant-typography {
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.6;
} 