.billingSummary {
  margin-top: 24px;
  margin-bottom: 80px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
}

.billingSummaryHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.billingSummaryHeader h3 {
  color: #fff;
  font-size: 16px;
  margin: 0;
}

.billingSummaryHeader svg {
  color: #10B2E1;
}

.billingSummaryItem {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 12px;
}

.billingSummaryItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.billingSummaryItemHeader h4 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.billingSummaryItemTime {
  color: #10B2E1;
  font-size: 13px;
}

.billingSummaryCharge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.billingSummaryChargeInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.billingSummaryChargeInfo span {
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
}

.billingSummaryChargeInfo small {
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
}

.billingSummaryChargeAmount {
  display: flex;
  align-items: center;
  gap: 8px;
}

.billingSummaryChargeAmount span {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: normal;
}

.coveredIcon {
  color: #34C759;
  font-size: 12px;
}

.notCoveredIcon {
  color: #FF9500;
  font-size: 12px;
}

.billingSummaryTotals {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.billingSummaryTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
}

.billingSummaryGrandTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.medicalAidTotal {
  color: #34C759;
  font-weight: 500;
}

.patientTotal {
  color: #FF9500;
  font-weight: 500;
}

/* Step Billing Styles */
.stepBilling {
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.stepBillingAmount {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  gap: 4px;
}

.patientPortion {
  color: #FF9500;
  font-size: 9px;
  opacity: 0.8;
} 