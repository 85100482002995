.conditions-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.condition-card {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}

.condition-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.condition-name {
  font-size: 16px;
  text-align: center;
}
