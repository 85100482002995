.mobile-virtual-visits {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 60px;

  overflow: hidden;
  -webkit-overflow-scrolling: none;
  /* Prevent text selection and touch behaviors */
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.virtual-content {
  padding: 24px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Hero Section */
.virtual-hero {
  text-align: center;
  margin-bottom: 16px;
  padding: 12px 0;
}

.virtual-hero h2 {
  color: white;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  background: linear-gradient(45deg, #10b2e1, #e91e63);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Apply to text elements as well */
  user-select: none;
  -webkit-user-select: none;
}

.virtual-hero p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 1.5;
  /* Apply to text elements as well */
  user-select: none;
  -webkit-user-select: none;
}

/* Features Grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 16px;
  margin-bottom: 100px;
}

.feature-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 16px;
  padding: 20px;
  text-align: center;
  transition: transform 0.2s ease;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.feature-card:active {
  transform: scale(0.98);
}

.feature-icon {
  font-size: 24px;
  color: #10b2e1;
  margin-bottom: 12px;
}

.feature-card h3 {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.feature-card p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.4;
}

/* Carousel styles */
.carousel-container {
  position: relative;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: auto;
}

.carousel-card {
  background: linear-gradient(145deg, #1a1f2e, #232936);
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2), inset 0 0 60px rgba(16, 178, 225, 0.03);
  border-radius: 20px;
  padding: 24px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  overflow: hidden;
  margin-top: 8px;
}

/* Shine effect */
.carousel-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(16, 178, 225, 0.03), transparent);
  animation: shine 8s infinite;
}

.step-number {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), inset 0 2px 4px rgba(255, 255, 255, 0.1);
}

.step-content h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  /* Apply to text elements as well */
  user-select: none;
  -webkit-user-select: none;
}

.step-content p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  line-height: 1.4;
}

.carousel-dots {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
  width: 100%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  20%,
  100% {
    left: 100%;
    transition-property: left;
  }
}

/* New button styles to match screenshot */
.start-consultation-button {
  position: fixed !important;
  bottom: 68px !important;
  left: 16px !important;
  right: 16px !important;
  height: 56px !important;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  border-radius: 12px !important;
  color: #10b2e1 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  z-index: 99 !important;
}

.start-consultation-button:hover,
.start-consultation-button:focus {
  background: rgba(16, 178, 225, 0.2) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

/* Info Button - adjust position relative to next button */
.info-button {
  position: fixed !important;
  right: 16px !important;
  bottom: 140px !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  color: #10b2e1 !important;
  font-size: 20px !important;
  z-index: 99;
  backface-visibility: hidden;
  transform: translateZ(0);
  will-change: transform;
}

.info-modal .steps-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.info-step {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 4px 0;
}

.step-icon {
  color: #10b2e1;
  font-size: 20px;
  flex-shrink: 0;
  width: 24px;
  text-align: center;
}

.step-text {
  flex: 1;
}

.step-text h4 {
  margin: 0 0 4px;
  color: white !important;
  font-size: 14px !important;
  font-weight: 500;
}

.step-text p {
  margin: 0;
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 13px !important;
  line-height: 1.4;
  text-align: left;
  white-space: pre-line;
}

.info-note {
  display: flex;
  gap: 12px;
  padding: 12px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 8px;
  margin-top: 16px;
}

.note-icon {
  color: #10b2e1;
  font-size: 16px;
  flex-shrink: 0;
}

.info-note p {
  margin: 0;
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 12px !important;
  line-height: 1.4;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.steps-animation {
  padding: 40px 0;
  margin-bottom: 40px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.step-item {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 28px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.step-number {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  font-size: 20px;
}

.step-text {
  font-size: 32px;
  letter-spacing: -0.5px;
}

.step-arrow {
  margin-left: 16px;
  color: #10b2e1;
  font-size: 24px;
}

/* New styles for the steps flow */
.steps-flow {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.step-card {
  padding: 8px 16px;
}

.step-content {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  transition: all 0.3s ease;
}

.step-number {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  color: white;
  flex-shrink: 0;
}

.step-text-content {
  flex: 1;
}

.step-text-content h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
}

.step-text-content p {
  font-size: 14px;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
}

.step-connector {
  height: 2px;
  margin: 4px 24px;
  border-radius: 1px;
}

/* Animation keyframes */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Modal Styles */
.info-modal .steps-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 0;
}

.info-step {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.step-icon {
  color: #10b2e1;
  font-size: 24px;
  margin-top: 4px;
}

.step-text h4 {
  margin: 0 0 4px;
  color: white !important;
  font-size: 16px;
  font-weight: 500;
}

.step-text p {
  margin: 0;
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 14px;
  line-height: 1.4;
}

.info-note {
  display: flex;
  gap: 12px;
  padding: 12px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
  margin-top: 8px;
}

.note-icon {
  color: #10b2e1;
  font-size: 16px;
  margin-top: 2px;
}

.info-note p {
  margin: 0;
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 13px;
  line-height: 1.4;
}

.hero-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
}

.hero-title span {
  background: linear-gradient(45deg, #10b2e1, #e91e63);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 600;
}

.hero-title .arrow-span {
  font-size: 16px;
  color: #10b2e1;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  display: flex;
  align-items: center;
}

.hero-title .arrow-span svg {
  color: #10b2e1;
  width: 16px;
  height: 16px;
}

.virtual-buttons {
  position: fixed;
  bottom: 68px;
  left: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 99;
}

/* Make both buttons share the same base styles */
.quick-connect-button,
.schedule-button {
  width: 100% !important;
  height: 56px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

/* Button hover states */
.quick-connect-button:hover,
.schedule-button:hover {
  background: rgba(16, 178, 225, 0.2) !important;
}

/* Icon styling within buttons */
.quick-connect-button .anticon,
.quick-connect-button svg,
.schedule-button .anticon,
.schedule-button svg {
  font-size: 18px !important;
  margin-left: 8px !important;
  color: #10b2e1 !important;
}

/* Adjust info button position */
.info-button {
  bottom: 200px !important;
}
