.custom-tabs-container {
  border-radius: 12px;
  overflow: hidden;
}

.custom-tabs-header {
  display: flex;

  padding: 0 16px;
  position: relative;
}

.custom-tab {
  flex: 1;
  padding: 16px 0;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
}

.custom-tab.active {
  color: #10b2e1;
  font-weight: 600;
}

.custom-tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #10b2e1;
  border-radius: 3px;
}

.custom-tabs-content {
  padding: 16px 8px;

  min-height: 300px;
}
