.personal-care-results {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.personal-care-results .plan-tabs-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
