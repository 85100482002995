.dependent-select {
  padding-bottom: 8px;
  border-bottom: 2px transparent solid;
  color: #717984;
  cursor: pointer;
}
.dependent-select.active {
  padding-bottom: 8px;
  border-bottom: 2px black solid;
  color: black;
}
