#agora-local-user-box,
#agora-remote-user-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

#agora-local-user-box.focused,
#agora-remote-user-box.focused {
  height: calc(100vh - 124px);
  left: 0px;
  width: 100vw;
  z-index: 1;
}

#agora-local-user-box.unfocused,
#agora-remote-user-box.unfocused {
  position: fixed;
  top: 90px;
  right: 10px;
  width: 100px;
  height: 100px;
  z-index: 4 !important;
  border-radius: 50%;
  overflow: hidden;
}

#agora-local-user-contols {
  position: fixed;
  bottom: 60px;
  width: 100vw;
  left: 0px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
