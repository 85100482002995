.mob-lifestyle-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  position: relative;
  padding: 16px 16px 0 16px;
  overflow-y: auto;
  padding-bottom: 140px;
}

.section-header {
  text-align: center;
  margin-bottom: 16px;
  padding: 12px;
  animation: fadeIn 0.3s ease-out;
  border-radius: 16px;
}

.lifestyle-sections {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 80px;
}

.lifestyle-card {
  border-radius: 16px;
  overflow: hidden;
  animation: fadeIn 0.3s ease-out;
}

.card-header {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.card-content {
  padding: 16px;
}

.bottom-actions {
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  padding: 0 16px 12px;
  display: flex;
  gap: 8px;
  z-index: 1000;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
