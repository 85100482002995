/* BOOKING SUMMARY BANNER */
.booking-summary-container {
  border-radius: 12px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.26);
  background: rgba(255, 255, 255, 1);
  display: flex;
  gap: 12px;
  align-items: center;
}
.booking-summary-date {
  padding: 12px;
  display: flex;
  height: 75px;
  width: 75px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #ffffff;
}
@media (max-width: 650px) {
  .booking-summary-container {
    flex-direction: column;
  }
  .booking-summary-date {
    width: 100%;
  }
}
