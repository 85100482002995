.mobile-dashboard-container {
  height: 100vh;
  padding-top: 76px;
  position: fixed;
  width: 100%;
  overflow: hidden;
}

.mobile-dashboard-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.booking-shortcuts-section {
  position: fixed;
  top: 76px;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: none;
}

.promo-carousel-section {
  position: fixed;
  top: 145px;
  left: 0;
  right: 0;
  z-index: 98;
}

.quick-buttons-section {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  padding: 16px;
}

.services-section {
  width: 100%;
  margin-top: 24px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}
