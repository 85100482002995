.macro-overview {
  background: transparent !important;
  border: none !important;
  border-radius: 16px !important;
  padding: 0 !important;
  width: 100%;
  overflow: hidden;
}

.macro-overview h3 {
  color: #fff !important;
  font-size: 20px;
  margin-bottom: 32px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.main-calories {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  padding: 0 16px;
}

.calories-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.calories-icon {
  font-size: 28px;
  color: #E91E63;
  margin-bottom: 8px;
}

.calories-value {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 4px;
}

.calories-label {
  font-size: 16px;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 16px;
}

.stat-box {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}

.stat-label {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.stat-value {
  font-size: 13px;
  color: #fff;
  font-weight: 600;
}

/* Remove old stats styles */
.calories-stats,
.stat-item,
.stat-divider {
  display: none;
}

.macros-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  width: 100%;
  box-sizing: border-box;
}

.macro-item {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

.macro-header {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #fff;
  margin-bottom: 6px;
  padding-right: 8px;
}

.macro-icon {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.macro-item:nth-child(1) .macro-icon {
  background: rgba(255, 152, 0, 0.1);
  color: #FF9800;
}

.macro-item:nth-child(2) .macro-icon {
  background: rgba(33, 150, 243, 0.1);
  color: #2196F3;
}

.macro-item:nth-child(3) .macro-icon {
  background: rgba(76, 175, 80, 0.1);
  color: #4CAF50;
}

.macro-item:nth-child(4) .macro-icon {
  background: rgba(233, 30, 99, 0.1);
  color: #E91E63;
}

.macro-header span {
  font-size: 13px;
  font-weight: 500;
}

.macro-value {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  opacity: 0.8;
  background: rgba(255, 255, 255, 0.05);
  padding: 2px 6px;
  border-radius: 4px;
}

/* Progress bar container */
.macro-item .ant-progress {
  line-height: 1;
  margin-left: 0;
  padding: 0 2px;
}

.macro-item .ant-progress-bg {
  height: 4px !important;
}

/* Adjust layout to prevent overlap */
.macro-header {
  position: relative;
  min-height: 20px;
}

/* Make sure content doesn't overflow */
.nutrition-plan {
  padding: 0;
  width: 100%;
}

/* Ensure proper spacing between sections */
@media screen and (min-width: 480px) {
  .macros-grid {
    padding: 0 16px;
  }

  .macros-grid {
    grid-template-columns: 1fr;
  }

  .main-calories .ant-progress {
    width: 180px !important;
    height: 180px !important;
  }
} 