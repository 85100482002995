/* Assessment Components Styling */

/* VitalSigns Component */
.vital-signs-card {
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.vital-signs-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.vital-signs-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fafafa;
}

.vital-sign-tab {
  padding: 12px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  text-align: center;
  position: relative;
  border-bottom: 3px solid transparent;
}

.vital-sign-tab.active {
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-bottom-color: #1890ff;
}

.vital-sign-tab.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 3px 3px 0 0;
}

.vital-sign-tab.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.vital-sign-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

.vital-sign-value {
  font-size: 13px;
  color: #666;
}

.vital-sign-value.normal {
  color: #52c41a;
}

.vital-sign-value.warning {
  color: #faad14;
}

.vital-sign-value.danger {
  color: #f5222d;
}

.vital-signs-graph-container {
  padding: 16px;
  background-color: #fff;
  min-height: 250px;
}

.vital-signs-actions {
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  gap: 8px;
  border-top: 1px solid #f0f0f0;
}

.vital-signs-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  text-align: center;
}

.vital-signs-empty-icon {
  font-size: 32px;
  margin-bottom: 16px;
  opacity: 0.5;
}

.vital-signs-empty-text {
  font-size: 16px;
  color: #999;
  max-width: 300px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .vital-sign-tab {
    min-width: 80px;
    padding: 10px 12px;
  }
  
  .vital-signs-graph-container {
    padding: 12px 8px;
  }
} 