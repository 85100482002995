.cs-test-setup {
  padding: 24px;
  max-width: 800px;
  margin: 0 auto;
}

.cs-test-setup__title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  margin-bottom: 24px;
}

.cs-test-setup__form {
  background: rgba(0, 21, 41, 0.6);
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Form overrides */
.cs-test-setup .ant-form-item-label > label {
  color: rgba(255, 255, 255, 0.85);
}

.cs-test-setup .ant-btn-primary {
  background: #65d2d8;
  border-color: #65d2d8;
  color: #141824;
}

.cs-test-setup .ant-btn-primary:hover {
  background: #4bc0c8;
  border-color: #4bc0c8;
}
