.mobile-health-stats {
  height: 100vh;
  display: flex;
  flex-direction: column;

  position: relative;
  overflow: hidden;
}

.stats-section {
  position: fixed;
  top: 76px;
  left: 0;
  right: 0;
  z-index: 10;

  overflow: hidden;
}

.stats-carousel {
  padding: 16px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  gap: 16px;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.stats-carousel::-webkit-scrollbar {
  display: none;
}

.stats-card {
  flex: 0 0 calc(100% - 32px);
  scroll-snap-align: center;
  border-radius: 16px;
  padding: 14px 16px;
  width: calc(100% - 32px);
  max-width: 320px;
  height: 220px;
  position: relative;
  border: 1px solid rgba(16, 178, 225, 0.15);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

/* Background glow */
.stats-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  z-index: 0;
  filter: blur(2px);
}

/* Button face shine */
.stats-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 13px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Ensure content stays above the glow effects */
.stats-card > * {
  position: relative;
  z-index: 2;
}

.heart-rate-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  margin-bottom: 0px;
}

.metric-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.metric-title {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.value-container {
  display: flex;
  align-items: baseline;
  gap: 4px;
  margin-top: -2px;
}

.bpm-value {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.bpm-label {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.heart-icon {
  font-size: 28px;
  opacity: 0.9;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.date-display {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
  margin-top: 0px;
  text-transform: uppercase;
}

.graph-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 140px;
  transform: translateY(-8px);
}

/* Override ant design plot styles */
.recharts-wrapper {
  background: transparent !important;
  margin-top: -18px;
}

.health-stats-indicators {
  padding: 16px 0;
  display: flex;
  gap: 24px;
  justify-content: center;
  background: transparent;
  position: relative;
  z-index: 3;
}

.health-stats-indicator {
  width: 16px;
  height: 3px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.health-stats-indicator.active {
  background: #36e91e;
  width: 48px;
}

.title-section {
  display: none;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  margin: 16px 0;
  letter-spacing: -0.3px;
  background: linear-gradient(45deg, #10b2e1, #e91e63);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.medications-section {
  position: fixed;
  top: 360px;
  left: 0;
  right: 0;
  bottom: 80px;
  overflow: hidden;

  padding-top: 16px;
}

.mobile-chronic-meds {
  margin-top: 0;
  height: 100%;
  position: relative;
}

.meds-list {
  height: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 16px;
  display: flex;
  gap: 16px;
  padding-bottom: 24px;
}
