.cs-location-picker {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background: transparent !important;
}

.cs-location-picker__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: transparent !important;
}

.cs-location-picker__item {
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
}

/* Glass effect */
.cs-location-picker__item::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  z-index: 0;
  filter: blur(2px);
}

.cs-location-picker__item:hover {
  transform: translateY(-2px);
  border-color: rgba(24, 144, 255, 0.3) !important;
}

.cs-location-picker__item-content {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  position: relative;
  z-index: 1;
}

.cs-location-picker__icon {
  color: #1890ff;
  font-size: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(24, 144, 255, 0.1);
  border-radius: 12px;
  flex-shrink: 0;
}

.cs-location-picker__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cs-location-picker__title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.cs-location-picker__address {
  font-size: 14px;
  margin: 0;
}

.cs-location-picker__distance {
  font-size: 14px;
  margin: 0;
}
