.ask-doc-wizard {
  width: 100%;
  height: calc(100vh - 120px);

  box-sizing: border-box;
  overflow-y: auto;
  padding: 0;
  position: relative;
  padding-bottom: 80px;
}

.wizard-screen {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 80px);
}

/* Welcome Screen */
.welcome-screen {
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.welcome-icon {
  font-size: 48px;
  color: #10b2e1;
  margin-bottom: 16px;
}

.welcome-screen h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 8px;
  background: linear-gradient(135deg, #10b2e1, #1084e1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome-screen p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.5;
  margin-bottom: 24px;
}

.feature-text {
  text-align: left;
  font-size: 16px;
  line-height: 2;
  color: rgba(255, 255, 255, 0.9) !important;
  background: rgba(16, 178, 225, 0.1);
  padding: 20px 32px;
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
}

/* Buttons */
.primary-button {
  background: linear-gradient(135deg, #10b2e1, #1084e1);
  border: none;
  border-radius: 12px;
  padding: 16px 32px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
}

.primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(16, 178, 225, 0.3);
}

/* Type Selection Screen */
.type-selection-screen {
  color: #fff;
}

.type-selection-screen h2 {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
  padding-top: 0;
  line-height: 1.2;
}

.consultation-types {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.type-button {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.type-button:hover {
  background: rgba(16, 178, 225, 0.15);
  border-color: rgba(16, 178, 225, 0.3);
}

.type-icon {
  font-size: 24px;
  color: #10b2e1;
  width: 40px;
}

.type-content {
  text-align: left;
  flex: 1;
}

.type-content h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 4px;
}

.type-content p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

/* Details Screen */
.details-screen h2 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 24px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 16px;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-section label {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

/* Scoped Ant Design Component Overrides */
.ask-doc-wizard .form-input {
  background: rgba(255, 255, 255, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
}

.ask-doc-wizard .form-input::placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
}

.ask-doc-wizard .radio-group {
  display: flex !important;
  gap: 8px;
}

.ask-doc-wizard .radio-button {
  background: rgba(255, 255, 255, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
}

.ask-doc-wizard .radio-button-checked {
  background: #10b2e1 !important;
  border-color: #10b2e1 !important;
}

.ask-doc-wizard .upload-area {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 2px dashed rgba(16, 178, 225, 0.2) !important;
  border-radius: 12px !important;
  padding: 24px !important;
}

.ask-doc-wizard .upload-icon {
  color: #10b2e1 !important;
  font-size: 32px !important;
  margin-bottom: 8px !important;
}

.ask-doc-wizard .upload-text {
  color: rgba(255, 255, 255, 0.6) !important;
}

/* Vitals Section */
.vitals-section {
  background: rgba(16, 178, 225, 0.05);
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(16, 178, 225, 0.1);
}

.vitals-section h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 16px;
}

.vitals-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.vital-input {
  display: flex;
  align-items: center;
  gap: 8px;
}

.vital-input svg {
  color: #10b2e1;
  font-size: 20px;
}

/* Upload Area */
.upload-area {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 2px dashed rgba(16, 178, 225, 0.2) !important;
  border-radius: 12px !important;
  padding: 24px !important;
}

.ant-upload-drag-icon {
  color: #10b2e1 !important;
  font-size: 32px !important;
  margin-bottom: 8px !important;
}

.ant-upload-text {
  color: rgba(255, 255, 255, 0.6) !important;
}

.submit-button {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 32px);
  max-width: 568px;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

/* Review Screen */
.review-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.review-section {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 20px;
}

.review-section h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 16px;
}

.consultation-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.option-button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  gap: 20px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
}

.option-button.selected {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
}

.option-button svg {
  font-size: 24px;
  color: #10b2e1;
  width: 24px;
}

.option-content {
  flex: 1;
  text-align: left;
}

.option-content h4 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #fff;
}

.option-content p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 12px;
}

.option-features {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
}

.feature-item svg {
  color: #10b2e1;
  font-size: 16px;
  width: 16px;
}

.price {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-top: 4px;
}

.price span:first-child {
  font-size: 24px;
  font-weight: 600;
  color: #10b2e1;
}

.price .duration {
  font-size: 11px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  margin-top: auto;
}

/* Summary Section Styling */
.summary-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
}

.summary-item span:first-child {
  color: rgba(255, 255, 255, 0.6);
  min-width: 100px;
}

.summary-item .symptoms-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
  justify-content: flex-end;
}

.symptom-tag {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px;
  color: #fff;
}

/* Payment Screen */
.payment-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.payment-summary {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 20px;
}

.payment-summary h3 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 16px;
}

.payment-summary .summary-item {
  margin-bottom: 8px;
}

.total {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 16px;
}

.card-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.card-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

/* Confirmation Screen */
.confirmation-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}

.confirmation-content {
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.success-icon {
  font-size: 64px;
  color: #2ecc71;
  margin-bottom: 8px;
}

.confirmation-content h2 {
  font-size: 24px;
  margin-bottom: 8px;
}

.confirmation-content p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}

.instruction {
  background: rgba(46, 204, 113, 0.1);
  color: #2ecc71 !important;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
}

.consultation-id {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 8px;
  padding: 12px 20px;
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

.consultation-id span:first-child {
  color: rgba(255, 255, 255, 0.6);
}

/* Button states */
.primary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primary-button:disabled:hover {
  transform: none;
  box-shadow: none;
}

/* Symptoms Grid */
.ask-doc-wizard .symptoms-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
}

.ask-doc-wizard .symptom-button {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 12px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 60px;
}

.ask-doc-wizard .symptom-button:hover {
  background: rgba(16, 178, 225, 0.1);
  border-color: rgba(16, 178, 225, 0.2);
  transform: translateY(-1px);
}

.ask-doc-wizard .symptom-button.selected {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
  box-shadow: 0 2px 8px rgba(16, 178, 225, 0.2);
}

/* Duration Grid */
.ask-doc-wizard .duration-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
}

.ask-doc-wizard .duration-button {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 12px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 48px;
}

.ask-doc-wizard .duration-button:hover {
  background: rgba(16, 178, 225, 0.1);
  border-color: rgba(16, 178, 225, 0.2);
  transform: translateY(-1px);
}

.ask-doc-wizard .duration-button.selected {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
  box-shadow: 0 2px 8px rgba(16, 178, 225, 0.2);
}

/* Vitals Section Updates */
.ask-doc-wizard .vitals-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.ask-doc-wizard .sync-button {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  border-radius: 8px;
  padding: 8px 12px;
  color: #10b2e1;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ask-doc-wizard .sync-button:hover {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
}

.ask-doc-wizard .sync-button svg {
  font-size: 14px;
}

/* Blood Pressure Input */
.ask-doc-wizard .blood-pressure {
  grid-column: span 2;
}

.ask-doc-wizard .bp-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.ask-doc-wizard .bp-inputs .form-input {
  width: 80px;
}

.ask-doc-wizard .bp-inputs span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

/* Responsive Adjustments */
@media screen and (max-width: 480px) {
  .ask-doc-wizard .symptoms-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .ask-doc-wizard .duration-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .ask-doc-wizard .vitals-grid {
    grid-template-columns: 1fr;
  }

  .ask-doc-wizard .blood-pressure {
    grid-column: auto;
  }
}

/* Severity Section */
.ask-doc-wizard .severity-selector {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 4px;
  display: inline-flex;
  gap: 4px;
}

.ask-doc-wizard .severity-button {
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.ask-doc-wizard .severity-button:hover {
  color: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.05);
}

.ask-doc-wizard .severity-button.selected {
  background: linear-gradient(135deg, #10b2e1, #1084e1);
  color: #fff;
  box-shadow: 0 2px 8px rgba(16, 178, 225, 0.3);
  font-weight: 600;
}

.ask-doc-wizard .severity-button.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  pointer-events: none;
}

/* Back Button and Content Layout */
.ask-doc-wizard .wizard-screen {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ask-doc-wizard .nav-section {
  width: 100%;
  padding: 8px 0;
  position: sticky;
  top: 60px;

  z-index: 10;
}

.ask-doc-wizard .back-button {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 6px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  opacity: 0.8;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-width: fit-content;
}

.ask-doc-wizard .back-button:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.ask-doc-wizard .content-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 8px;
}

.ask-doc-wizard .content-section h2 {
  font-size: 24px;
  color: #fff;
  margin: 0 0 24px 0;
  line-height: 1.2;
}

/* Update form container to maintain layout */
.ask-doc-wizard .form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

/* Navigation and Header Layout */
.ask-doc-wizard .nav-section {
  margin-bottom: 16px;
}

.ask-doc-wizard .back-button {
  background: none;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 0;
  font-size: 16px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.ask-doc-wizard .back-button:hover {
  opacity: 1;
}

.ask-doc-wizard .content-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ask-doc-wizard .content-section h2 {
  font-size: 24px;
  color: #fff;
  margin: 0 0 24px 0;
  line-height: 1.2;
}

/* Review Screen Specific Styles */
.ask-doc-wizard .review-screen {
  position: relative;
  padding-top: 8px;
}

.ask-doc-wizard .review-screen h2 {
  color: #fff;
  font-size: 24px;
  margin: 4px 0 32px 0;
  line-height: 1.2;
}

.ask-doc-wizard .review-screen .back-button {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 6px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  opacity: 0.8;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-top: -8px;
  margin-bottom: 4px;
}

.ask-doc-wizard .review-screen .back-button:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.ask-doc-wizard .review-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 80px;
  margin-top: 16px;
}

/* Details Screen Specific Styles */
.ask-doc-wizard .details-screen {
  position: relative;
}

.ask-doc-wizard .details-screen h2 {
  color: #fff;
  font-size: 24px;
  margin: 16px 0 24px 0;
  line-height: 1.2;
}

.ask-doc-wizard .details-screen .back-button {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 6px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  opacity: 0.8;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-bottom: 8px;
}

.ask-doc-wizard .details-screen .back-button:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.ask-doc-wizard .details-screen .form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 80px;
}

.option-content .steps-included {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 6px 0 12px 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
}

.step-item {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1.2;
}

.step-item .check-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 50%;
  background: rgba(46, 204, 113, 0.15);
  color: #2ecc71;
  font-size: 9px;
  box-shadow: 0 0 8px rgba(46, 204, 113, 0.3);
}

/* Payment Screen Specific Styles */
.ask-doc-wizard .payment-screen {
  position: relative;
  padding-top: 16px;
}

.ask-doc-wizard .payment-screen h2 {
  color: #fff;
  font-size: 24px;
  margin: 4px 0 40px 0;
  line-height: 1.2;
}

.ask-doc-wizard .payment-screen .back-button {
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 6px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  opacity: 0.8;
  transition: all 0.2s ease;
  white-space: nowrap;
  margin-top: -12px;
  margin-bottom: 4px;
}

.ask-doc-wizard .payment-screen .payment-container {
  margin-top: 8px;
}

.ask-doc-wizard .payment-screen .back-button:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}
