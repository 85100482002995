.exercise-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #141824;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Background gradient and shine effects */
.exercise-item::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 8px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

/* Button face shine */
.exercise-item::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 7px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7), 0 0 15px rgba(255, 255, 255, 0.05),
    inset 0 2px 2px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.exercise-item:hover {
  transform: translateY(-1px);
  border-color: rgba(16, 178, 225, 0.3);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.8), 0 0 20px rgba(16, 178, 225, 0.1);
}

.exercise-item.completed {
  padding: 16px 16px 16px 20px;
  border-color: rgba(82, 255, 82, 0.4);
}

.exercise-item.completed::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #3cff3c;
  box-shadow: 0 0 10px rgba(60, 255, 60, 0.4), 0 0 20px rgba(60, 255, 60, 0.2);
  z-index: 2;
}

.exercise-item-content {
  width: 100%;
  position: relative;
  z-index: 2;
}

.exercise-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.exercise-name {
  color: #fff;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.info-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
}

.exercise-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.exercise-metrics {
  display: flex;
  gap: 16px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detail-label {
  font-size: 12px;
}

.detail-value {
  font-weight: 500;
}

.duration-icon {
  color: rgba(255, 255, 255, 0.5);
  margin-right: 6px;
}

.completed-time {
  color: rgba(82, 255, 82, 0.8);
  font-size: 12px;
  margin-top: 4px;
}
