.cs-overview__tests-card {
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: none !important;
}

.cs-overview__tests-card .ant-typography {
  margin: 0 !important;
}

.cs-overview__test-item {
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.cs-overview__test-item:last-child {
  border-bottom: none;
}

.cs-overview__test-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.cs-overview__test-details {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* Badge overrides */
.cs-overview__test-details .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.cs-overview__test-details .ant-badge-status-text {
  font-size: 14px;
}

/* Button styling */
.cs-overview__test-item .ant-btn-primary {
  background: #1890ff !important;
  border-color: #1890ff !important;
  box-shadow: 0 2px 0 rgba(24, 144, 255, 0.1);
  min-width: 90px;
}

.cs-overview__test-item .ant-btn-primary:hover {
  background: #40a9ff !important;
  border-color: #40a9ff !important;
}

/* Base Container Styles */
.cs-results-center {
  border-radius: 12px;
  padding: 16px;
  backdrop-filter: blur(10px);
}

/* Header Styles */
.cs-results-center__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.cs-results-center__title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}

.cs-results-center__description {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.85);
  margin: 0 0 12px 0;
  padding: 0 4px;
  font-weight: 400;
}

.cs-results-center__collapse-btn {
  padding: 4px 8px !important;
  height: 32px !important;
}

/* Test Items Container */
.cs-results-center__tests {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

/* Individual Test Item */
.cs-results-center__test-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
  transition: all 0.3s ease;
}

.cs-results-center__main-content {
  display: flex;
  gap: 12px;
}

.cs-results-center__test-info {
  flex: 1;
  min-width: 0;
}

/* Name Row Styling */
.cs-results-center__name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 4px;
}

.cs-results-center__test-name {
  font-size: 14px !important;
  margin: 0 !important;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Time Row Styling */
.cs-results-center__time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.cs-results-center__time {
  font-size: 12px !important;
}

/* Button Styles */
.cs-results-center__book-btn {
  min-width: 80px;
  height: 24px !important;
  padding: 0 12px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
}

.cs-results-center__info-btn {
  color: #65d2d8 !important;
  padding: 4px !important;
  height: 24px !important;
  width: 24px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: transparent !important;
}

.cs-results-center__info-btn:hover {
  color: #40a9ff !important;
  background: rgba(101, 210, 216, 0.1) !important;
}

/* Animation Section */
.cs-results-center__animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  opacity: 0.9;
  overflow: hidden;
  max-width: 100%;
}

.cs-results-center__animation > div {
  transform: scale(1.2);
}

/* AI Explanation Text */
.cs-results-center__ai-explanation {
  font-size: 12px !important;
  line-height: 1.5 !important;
  margin: 2px 0 12px 0 !important;
  padding: 0 4px;
  text-align: left;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 400;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .cs-results-center {
    padding: 12px;
  }

  .cs-results-center__test-item {
    padding: 10px;
  }

  .cs-results-center__book-btn {
    min-width: 70px;
  }
}
