.miniTasksCard {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  background: #fff;
  width: 100%;
  display: block;
  min-height: 450px;
}

.miniTasksCard :global(.ant-card-head) {
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 16px;
  background: #fafafa;
  border-radius: 8px 8px 0 0;
}

.miniTasksCard :global(.ant-card-head-title) {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
}

.miniTasksCard :global(.ant-card-body) {
  padding: 16px;
  min-height: 380px;
  display: flex;
  flex-direction: column;
}

.addButton {
  border-radius: 4px;
}

.tasksList {
  padding: 8px 0;
  max-height: 350px;
  min-height: 280px;
  overflow-y: auto;
  width: 100%;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}

.emptyIcon {
  font-size: 48px;
  color: #d9d9d9;
}

.emptyState span {
  max-width: 280px;
  line-height: 1.5;
}

/* Improved scrollbar styling */
.tasksList::-webkit-scrollbar {
  width: 6px;
}

.tasksList::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 3px;
}

.tasksList::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
}

.tasksList::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.cardHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.tabWithBadge {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tabWithBadge :global(.ant-badge) {
  margin-top: -2px;
}

.headerButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.preAdmissionButton {
  border-radius: 4px;
} 