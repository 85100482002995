.cs-header {
  padding: 16px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cs-header__back-btn.ant-btn {
  padding: 0;
  font-size: 16px;
  margin-right: 16px;
}

.cs-header__title-container {
  flex: 1;
  text-align: center;
}

.cs-header__title {
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
}

.cs-header__title-icon {
  opacity: 0.9;
}

.cs-header__admin-btn.ant-btn {
  padding: 0;
  font-size: 20px;
  min-width: 40px;
}
