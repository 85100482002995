.notificationsPopover {
  width: 360px;
}

.notificationsPopover :global(.ant-popover-inner) {
  padding: 0;
}

.notificationsContainer {
  max-height: 400px;
  display: flex;
  flex-direction: column;
}

.notificationsHeader {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationsList {
  overflow-y: auto;
}

.notificationsList :global(.ant-list-item) {
  padding: 12px 16px;
  cursor: default;
}

.notificationItem {
  transition: background-color 0.3s ease;
}

.notificationItem:hover {
  background-color: #fafafa;
}

.unread {
  background-color: #e6f7ff;
}

.unread:hover {
  background-color: #e6f7ff;
}

.notificationTime {
  font-size: 12px;
}

.notificationDescription {
  font-size: 12px;
  color: #8c8c8c;
}

.notificationBell {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationBell:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.85);
} 