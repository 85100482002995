.profile-container {
  margin: 0;
  padding: 0;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #3498db, #e74c3c);
  overflow: hidden;
}

.profile-background {
  position: relative;
  width: 100%;
  height: 100%;
}

.profile-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(-45deg, #2ecc71, #2ecc71 10px, #3498db 10px, #3498db 20px);
  opacity: 0.8;
}
