.mobile-macro-tracker {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  position: relative;
}

.macro-content {
  padding: 76px 16px 80px;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 200px;
}

/* Adjust floating button container position */
.floating-camera-button {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 0 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Button row styling - for the two buttons at top */
.button-row {
  display: flex;
  gap: 8px;
  width: 100%;
}

/* Apply to both button row buttons and single buttons */
.button-row .action-button,
.floating-camera-button > .action-button {
  flex: 1;
  min-width: 0;
}

/* Match the back button styling from MobileHealthyAlternatives */
.mobile-macro-tracker .action-button {
  width: 100% !important;
  height: 48px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  padding: 0 !important; /* Added to ensure consistent height */
  line-height: 48px !important; /* Added to ensure consistent height */
}

.mobile-macro-tracker .action-button:hover {
  background: rgba(16, 178, 225, 0.2) !important;
}

.mobile-macro-tracker .action-button svg {
  font-size: 16px !important;
  color: #10b2e1 !important;
}

.mobile-macro-tracker .action-button span {
  color: #10b2e1 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
