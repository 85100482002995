/* GeneralStats.css - Styles for the General Statistics component */

.general-stats-card {
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.general-stats-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.general-stats-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fafafa;
  width: 100%;
}

.general-stats-tab {
  padding: 12px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  border-bottom: 3px solid transparent;
}

.general-stats-tab.active {
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-bottom-color: #1890ff;
}

.general-stats-tab.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.general-stats-title {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.general-stats-value {
  font-size: 16px;
  font-weight: normal;
}

/* Status color classes */
.general-stats-value.normal {
  color: #FF69B4;
}

.general-stats-value.warning {
  color: #faad14;
}

.general-stats-value.danger {
  color: #f5222d;
}

.general-stats-graph-container {
  padding: 16px;
  background: #ffffff;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-stats-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background: #ffffff;
  min-height: 300px;
}

.general-stats-empty-icon {
  font-size: 48px;
  color: #d9d9d9;
  margin-bottom: 16px;
}

.general-stats-empty-text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
}

.general-stats-reference {
  padding: 8px 16px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  border-top: 1px solid #f0f0f0;
  background: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .general-stats-tab {
    min-width: 100px;
    padding: 10px 12px;
  }
  
  .general-stats-title {
    font-size: 13px;
  }
  
  .general-stats-value {
    font-size: 14px;
  }
} 