.test-detail-range-info {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 16px;
}

.test-detail-range-values {
  display: flex;
  gap: 24px;
  margin-bottom: 12px;
}

.test-detail-range-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.test-detail-range-item .ant-typography:first-child {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.test-detail-range-number.ant-typography {
  color: #65D2D8;
  font-size: 16px;
  font-weight: 500;
}

.test-detail-range-interpretation.ant-typography {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 1.5;
}

.test-detail-section h4.ant-typography {
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.reference-range-card {
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Range Key Styling */
.range-key {
  margin-bottom: 4px;
}

.range-key-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: linear-gradient(
    145deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
  min-height: 52px;
}

/* Glossy shine effect */
.range-key-item::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.03) 30%,
    rgba(255, 255, 255, 0.05) 40%,
    transparent 100%
  );
  transform: rotate(-45deg);
  animation: shine 3s infinite;
  pointer-events: none;
}

@keyframes shine {
  0% {
    transform: translateX(-200%) translateY(-200%) rotate(-45deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(-45deg);
  }
}

/* Color coding for icons */
.range-key-item.low .key-icon {
  color: #FF7A9F;
}

.range-key-item.normal .key-icon {
  color: #B5D777;
}

.range-key-item.high .key-icon {
  color: #FF9F7A;
}

/* Keep the glass effect styles */
.range-key-item.low {
  box-shadow: 0 0 20px rgba(255, 122, 159, 0.1);
}

.range-key-item.normal {
  box-shadow: 0 0 20px rgba(181, 215, 119, 0.1);
}

.range-key-item.high {
  box-shadow: 0 0 20px rgba(255, 159, 122, 0.1);
}

.key-icon {
  font-size: 10px;
  margin-top: 4px;
  filter: drop-shadow(0 0 3px currentColor);
}

.key-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  z-index: 1;
}

.key-label {
  color: rgba(255, 255, 255, 0.45);
  font-size: 12px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1;
}

.key-value {
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1;
  margin-top: 2px;
}

/* Unit display */
.range-unit {
  padding: 6px 10px;
  background: rgba(101, 210, 216, 0.08);
  border-radius: 8px;
}

.range-unit .ant-typography {
  color: #65D2D8;
  font-size: 12px;
  font-weight: 500;
}

/* Notes section */
.range-notes {
  padding: 10px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  border-left: 3px solid #65D2D8;
}

.range-notes .ant-typography {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 1.6;
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .range-key-item {
    padding: 6px;
  }
  
  .key-value {
    font-size: 13px;
  }
}

/* Interpretation styling */
.interpretation-section {
  margin-bottom: 8px;
}

.interpretation-section:last-child {
  margin-bottom: 0;
}

.interpretation-title {
  display: block;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 13px;
}

.interpretation-item {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 8px;
  margin-bottom: 2px;
}

.interpretation-label {
  color: rgba(255, 255, 255, 0.65);
  min-width: 90px;
  font-size: 12px;
}

.interpretation-value {
  color: #65D2D8;
  font-weight: 500;
  font-size: 12px;
} 