.plan-tabs-content {
  height: 100vh;

  padding-top: 60px; /* Header height */
}

/* Tabs Navigation Container */
.plan-tabs-content .ant-tabs-nav {
  background: #141824;
  margin: 0 !important;
  padding: 8px 0;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.plan-tabs-content .ant-tabs-nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.plan-tabs-content .ant-tabs-nav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Tab Bar - the line that contains all tabs */
.plan-tabs-content .ant-tabs-nav .ant-tabs-nav-list {
  display: flex;
  gap: 24px;
  padding: 0 16px;
  padding-right: 24px;
  flex-wrap: nowrap;
  width: auto;
}

/* Individual Tab */
.plan-tabs-content .ant-tabs-tab {
  padding: 8px 0;
  margin: 0 !important;
  transition: all 0.3s ease;
  opacity: 0.7;
  white-space: nowrap;
  flex-shrink: 0;
  min-width: max-content;
}

/* Active Tab */
.plan-tabs-content .ant-tabs-tab-active {
  opacity: 1;
}

/* Tab Inner Content */
.plan-tabs-content .ant-tabs-tab-btn {
  color: rgba(255, 255, 255, 0.85) !important;
  font-weight: 500;
}

/* Active Tab Indicator Line */
.plan-tabs-content .ant-tabs-ink-bar {
  background: #10b2e1 !important;
  height: 3px !important;
  border-radius: 3px 3px 0 0;
}

/* Tab Label Container */
.plan-tabs-content .tab-label {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 14px;
}

/* Tab Icon */
.plan-tabs-content .tab-label .tab-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
}

/* Tab Text */
.plan-tabs-content .tab-label span,
.plan-tabs-content .tab-label svg {
  color: inherit !important;
}

/* Active Tab Label */
.plan-tabs-content .ant-tabs-tab-active .tab-label {
  color: #10b2e1 !important;
}

/* Make the tab content area scrollable */
.plan-tabs-content .ant-tabs-content-holder {
  height: calc(100vh - 110px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Scrollable tabs container */
.plan-tabs-content .scrollable-tabs {
  width: 100%;
}

/* Remove bottom borders */
.plan-tabs-content .ant-tabs-nav::before {
  border-bottom: none !important;
}

.plan-tabs-content .ant-tabs-nav-wrap::before,
.plan-tabs-content .ant-tabs-nav-wrap::after {
  display: none !important;
}
