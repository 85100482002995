@keyframes gradientMove {
  0% {
    background-position: 200% center;
  }
  12.5% {
    background-position: 0% center;
  }

  0% {
    background-position: 200% center;
  }
}

.TITLE_OVERIDE {
  position: relative !important;
  font-size: 17;
  transition: 300ms ease !important ;
  -webkit-background-clip: text !important;
  background-size: 200% auto !important;
  background-clip: text !important;
  color: transparent !important;
  animation: gradientMove 4s linear infinite;
}
