.category-scores {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 24px 0;
  width: calc(100% - 48px);
  box-sizing: border-box;
}

.category-scores .category-item {
  background: rgba(20, 24, 36, 0.6);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 16px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.category-scores .category-item:hover {
  background: rgba(26, 31, 46, 0.8);
  border-color: rgba(16, 178, 225, 0.3);
}

.category-scores .category-item.expanded {
  background: rgba(26, 31, 46, 0.9);
}

.category-scores .category-header {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.category-scores .category-icon {
  color: #10b2e1;
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.category-scores .category-label {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  min-width: 100px;
  flex-shrink: 0;
}

.category-scores .category-details {
  margin-top: 20px;
  padding-left: 40px; /* Align with content after icon */
}

.category-scores .category-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.category-scores .trend-info {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(0, 0, 0, 0.2);
  padding: 8px 12px;
  border-radius: 8px;
  width: fit-content;
}

.category-scores .trend-value {
  font-size: 16px;
  font-weight: 600;
}

.category-scores .trend-value.positive {
  color: #52c41a;
}

.category-scores .trend-value.negative {
  color: #f5222d;
}

.category-scores .trend-value.neutral {
  color: #faad14;
}

.category-scores .trend-period {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}

/* Ant Design Progress customization */
.category-scores .ant-progress {
  margin-right: 0;
  flex: 1;
}

.category-scores .ant-progress-text {
  color: #fff !important;
  font-weight: 500;
}

.category-scores .ant-progress-inner {
  background-color: rgba(16, 178, 225, 0.1) !important;
}

.category-scores .ant-progress-bg {
  height: 8px !important;
}
