.cs-admin {
  min-height: 100vh;
  padding: 24px;
}

.cs-admin__header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.cs-admin__header h1 {
  color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  margin: 0;
}

.cs-admin__back {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #65D2D8;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
}

.cs-admin__content {
  background: rgba(0, 21, 41, 0.6);
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cs-admin__toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;
}

.cs-admin__search {
  max-width: 300px;
}

.cs-admin__featured {
  margin-bottom: 24px;
  padding: 16px;
  background: rgba(0, 21, 41, 0.3);
  border-radius: 8px;
}

.cs-admin__featured h2 {
  color: rgba(255, 255, 255, 0.85);
  font-size: 18px;
  margin: 0 0 16px 0;
}

.cs-admin__featured-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.cs-admin__featured-tag {
  background: rgba(101, 210, 216, 0.1) !important;
  border-color: #65D2D8 !important;
  color: #65D2D8 !important;
}

.cs-admin__featured-help {
  color: rgba(255, 255, 255, 0.45);
  font-size: 14px;
  margin: 0;
}

.cs-admin__feature-btn {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Table overrides */
.cs-admin .ant-table {
  background: transparent;
}

.cs-admin .ant-table-thead > tr > th {
  background: rgba(0, 21, 41, 0.3);
  color: rgba(255, 255, 255, 0.85);
}

.cs-admin .ant-table-tbody > tr > td {
  background: transparent;
  color: rgba(255, 255, 255, 0.65);
}

.cs-admin .ant-table-tbody > tr:hover > td {
  background: rgba(255, 255, 255, 0.05) !important;
} 