.container {
  padding: 16px;
  width: 100%;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.container :global(.ant-row) {
  height: 100%;
}

.container :global(.ant-col) {
  display: flex;
  flex-direction: column;
}

.container :global(.ant-col > *:last-child) {
  flex: 1;
}

.card {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 16px;
  background: #fff;
}

.timer {
  font-weight: bold;
  color: red;
}

.aiCard {
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%);
  overflow: hidden;
}

.aiCard :global(.ant-card-head) {
  background: linear-gradient(145deg, #f8f9ff 0%, #ffffff 100%);
  border-bottom: 1px solid #f0f0f0;
  padding: 16px 24px;
}

.aiCard :global(.ant-card-head-title) {
  font-size: 18px;
  color: #262626;
  font-weight: 600;
}

.aiCard :global(.ant-card-body) {
  padding: 20px;
} 