.personal-care-intro {
  composes: mobile-page-container;
  padding: 20px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-y: auto;
}

.personal-care-intro h1 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 12px;
  background: linear-gradient(90deg, #10b2e1, #e91e63);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intro-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 1.5;
  max-width: 600px;
  margin-bottom: 24px;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 32px;
  width: 100%;
  max-width: 600px;
}

.feature-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.feature-card:hover {
  background: rgba(16, 178, 225, 0.1);
  transform: translateY(-2px);
}

.feature-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  margin-bottom: 4px;
}

.feature-card h3 {
  color: #fff;
  font-size: 16px;
  margin: 0;
}

.feature-card p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin: 0;
  line-height: 1.4;
}

.start-button {
  width: 100%;
  max-width: 400px;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid #10b2e1;
  color: #10b2e1;
  margin-top: 16px;
  margin-bottom: 80px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

/* Glowing border effect */
.start-button::before {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(90deg, #10b2e1, #e91e63, #10b2e1);
  background-size: 200% 100%;
  animation: moveGradient 2s linear infinite;
  z-index: -1;
}

.start-button::after {
  content: '';
  position: absolute;
  inset: 1px;

  border-radius: 7px;
  z-index: -1;
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}

.start-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 0 20px rgba(16, 178, 225, 0.4), 0 0 30px rgba(233, 30, 99, 0.3);
  background: rgba(16, 178, 225, 0.15);
}

.personal-care-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.plan-choice-modal {
  .modal-content {
    text-align: center;
    padding: 20px;

    h3 {
      color: #fff;
      font-size: 20px;
      margin-bottom: 8px;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 24px;
    }
  }

  .modal-buttons {
    display: flex;
    gap: 12px;
    justify-content: center;

    button {
      height: 44px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 20px;
    }

    .continue-button {
      background: #10b2e1;
      border: none;
      color: #fff;
    }

    .new-button {
      background: rgba(16, 178, 225, 0.1);
      border: 1px solid #10b2e1;
      color: #10b2e1;
    }
  }
}
