.cs-overview__banner {
  margin: -16px -16px 24px -16px;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  height: 200px;
  position: relative;
}

.cs-overview__banner-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cs-overview__banner-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.cs-overview__banner-logo {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 2;
  width: 160px;
  height: auto;
}

.cs-overview__banner-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
} 