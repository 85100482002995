.quick-stats-card {
  border-radius: 16px;
  padding: 16px;
  margin: 4px 24px 0;
  width: calc(100% - 48px);
  box-sizing: border-box;
  position: relative;
  border: 1px solid rgba(16, 178, 225, 0.15);
}

/* Background glow */
.quick-stats-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 16px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

/* Card face shine */
.quick-stats-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7), 0 0 15px rgba(255, 255, 255, 0.05),
    inset 0 2px 2px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

/* Ensure content stays above glass effects */
.quick-stats-card .stats-content {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 16px;
}

.bmi-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  border-right: 1px solid rgba(16, 178, 225, 0.1);
}

.bmi-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.bmi-value strong {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  background: linear-gradient(135deg, #10b2e1, #1c64f2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.measurements-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.measurement-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.measurement-value {
  display: flex;
  align-items: center;
  gap: 8px;
}

.measurement-value .label-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.label-row {
  display: flex;
  align-items: center;
  gap: 6px;
}

.icon {
  color: rgba(16, 178, 225, 0.8);
  font-size: 14px;
  width: 14px;
}

.label {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.measurement-value strong {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  gap: 2px;
  margin-top: 2px;
}

.measurement-value small {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
}

/* Ant Design Tag customization */
.stats-card .ant-tag {
  border: none;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
  margin: 0;
}
