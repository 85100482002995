.workout-plan-tab {
  padding: 0;
  background: transparent;
}

.workouts-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Base styles for the workout plan tab */
:root {
  --dark-bg: #141824;
  --darker-bg: #1C2536;
  --primary-blue: #10B2E1;
  --primary-blue-alpha: rgba(16, 178, 225, 0.15);
  --primary-blue-light: rgba(16, 178, 225, 0.05);
  --primary-green: #4CAF50;
  --primary-yellow: #FFC107;
}

/* Ensure proper spacing and layout */
.workout-plan-tab {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 80px; /* Space for bottom navigation */
} 