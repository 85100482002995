.personal-care-form {
  height: 100%;
  min-height: 100vh;

  padding: 16px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.form-progress {
  height: 4px;
  background: rgba(16, 178, 225, 0.1);
  margin-bottom: 24px;
  border-radius: 2px;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #10b2e1, #e91e63);
  transition: width 0.3s ease;
  border-radius: 2px;
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  flex: 1;
  margin-bottom: 24px;
}

.step-content h2 {
  color: #fff;
  font-size: 22px;
  margin: 0 0 16px 0;
  text-align: center;
  background: linear-gradient(90deg, #10b2e1, #e91e63);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.options-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 24px;
  padding-bottom: 60px;
}

/* Remove the special grid for dietary preferences */
.options-grid[data-step='dietaryPreferences'] {
  /* Keep the same layout as other steps */
  grid-template-columns: 1fr;
  gap: 12px;
}

/* Adjust card height back to normal */
.options-grid[data-step='dietaryPreferences'] .option-card {
  height: 72px;
  padding: 16px;
}

.options-grid[data-step='dietaryPreferences'] .option-icon {
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.option-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 16px;
  height: 72px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  position: relative;
  overflow: hidden;
  will-change: transform, background, border-color, box-shadow;
}

.option-content {
  flex: 1;
  margin-right: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}

.option-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 2px solid rgba(16, 178, 225, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  flex-shrink: 0;
  position: relative;
  margin-left: auto;
}

.option-card.selected .option-checkbox {
  background: #10b2e1;
  border-color: #10b2e1;
  box-shadow: 0 0 10px #10b2e1, 0 0 20px #10b2e1, inset 0 0 5px rgba(255, 255, 255, 0.5);
}

.check-icon {
  color: #fff;
  font-size: 14px;
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5));
}

.option-card.selected {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
  box-shadow: 0 2px 8px rgba(16, 178, 225, 0.2);
  transform: none;
}

.option-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, #10b2e1, #e91e63);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  flex-shrink: 0;
}

.option-card h3 {
  color: #fff;
  font-size: 16px;
  margin: 0;
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option-card p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin: 0;
  text-align: left;
  width: 100%;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-navigation {
  display: flex;
  gap: 12px;
  padding: 16px;
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  z-index: 100;
}

.nav-button {
  display: none;
}

.nav-button.next {
  width: 100%;
  max-width: 400px;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid #10b2e1;
  color: #10b2e1;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-out;
  will-change: transform, box-shadow;
}

.nav-button.next::before {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(90deg, #10b2e1, #e91e63, #10b2e1);
  background-size: 200% 100%;
  animation: moveGradient 2s linear infinite;
  z-index: -1;
}

.nav-button.next::after {
  content: '';
  position: absolute;
  inset: 1px;

  border-radius: 7px;
  z-index: -1;
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}

.nav-button.next:hover {
  transform: translateY(-1px);
  background: rgba(16, 178, 225, 0.15);
  box-shadow: 0 0 20px rgba(16, 178, 225, 0.4), 0 0 30px rgba(233, 30, 99, 0.3);
}

.nav-button.next:active {
  transform: translateY(0);
  box-shadow: 0 0 10px rgba(16, 178, 225, 0.3), 0 0 15px rgba(233, 30, 99, 0.2);
}

.option-card:not(.selected):hover {
  transform: translateY(-2px);
  background: rgba(16, 178, 225, 0.1);
}

.nav-button.next.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

/* Add styles for back button */
.nav-button.back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease-out;
}

.nav-button.back:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

.measurements-grid {
  display: grid;
  gap: 16px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.measurement-input {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 16px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 12px;
}

.measurement-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(45deg, #10b2e1, #e91e63);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
}

.measurement-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.measurement-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

.measurement-input input {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 0;
}

.measurement-input input:focus {
  outline: none;
}

.measurement-unit {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  padding-right: 8px;
}

.submission-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  z-index: 1000;
}

.submission-overlay p {
  color: #fff;
  font-size: 16px;
  margin: 0;
  text-align: center;
}
