.cs-quick-book {
  width: 100%;
  min-height: 100vh;
  padding: 16px;
}

.cs-quick-book__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.cs-quick-book__header .ant-typography {
  margin: 0;
}

.cs-quick-book__header .ant-typography-secondary {
  margin: 0;
}

/* Title specific styles */
.cs-quick-book__header h2.ant-typography {
  font-size: 24px;
  font-weight: 600;
}

.cs-quick-book__back-btn {
  padding: 0;
  margin-bottom: 8px;
  width: fit-content;
}

.cs-quick-book__steps {
  margin-bottom: 24px;
}

/* Override Ant Steps styles */
.cs-quick-book__steps {
  margin-bottom: 24px;
}

.cs-quick-book__steps .ant-steps-item-icon {
  background: rgba(24, 144, 255, 0.1) !important;
  border-color: #1890ff !important;
}

.cs-quick-book__steps .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff !important;
}

.cs-quick-book__steps .ant-steps-item-finish .ant-steps-item-icon {
  background: #1890ff !important;
}
