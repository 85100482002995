.cs-confirmation {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  background: transparent !important;
}

.cs-confirmation__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.cs-confirmation__icon {
  font-size: 32px;
  color: #1890ff;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 8px;
}

.cs-confirmation__title {
  margin: 0 !important;
}

.cs-confirmation__details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cs-confirmation__section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cs-confirmation__section-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 4px;
}

.cs-confirmation__section-icon {
  color: #1890ff;
  font-size: 16px;
}

.cs-confirmation__section-title {
  font-size: 14px;
  font-weight: 500;
}

.cs-confirmation__card {
  background: rgba(255, 255, 255, 0.03) !important;
  backdrop-filter: blur(10px);
  border-radius: 12px !important;
  padding: 16px;
  display: flex;
  gap: 16px;
  position: relative;
  overflow: hidden;
}

/* Glass effect */
.cs-confirmation__card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  z-index: 0;
  filter: blur(2px);
}

.cs-confirmation__test-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #1890ff;
  font-size: 24px;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}

.cs-confirmation__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  z-index: 1;
}

.cs-confirmation__primary {
  font-size: 16px;
  font-weight: 500;
}

.cs-confirmation__secondary {
  font-size: 14px;
}

.cs-confirmation__distance {
  font-size: 14px;
}

.cs-confirmation__prep-note {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;
  background: rgba(82, 196, 26, 0.1);
  border-radius: 8px;
}

.cs-confirmation__check {
  color: #52c41a;
  font-size: 14px;
}

.cs-confirmation__prep {
  font-size: 14px;
}

.cs-confirmation__divider {
  margin: 8px 0 !important;
}

.cs-confirmation__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.cs-confirmation__submit {
  width: 100%;
  height: 48px !important;
  font-size: 16px !important;
  background: linear-gradient(45deg, #1890ff, #096dd9) !important;
  border: none !important;
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
  transition: all 0.3s ease !important;
}

.cs-confirmation__submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(24, 144, 255, 0.4);
}

.cs-confirmation__note {
  font-size: 14px;
  text-align: center;
}

/* Animation states */
.cs-confirmation__animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 24px;
  padding: 20px;
  backdrop-filter: blur(10px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cs-confirmation__lottie {
  width: 300px;
  height: 300px;
  filter: drop-shadow(0 0 20px rgba(24, 144, 255, 0.2));
  border-radius: 20px;
  overflow: hidden;
}

.cs-confirmation__processing {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.3px;
}

/* Success state */
.cs-confirmation__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 16px;
  animation: fadeIn 0.5s ease;
}

.cs-confirmation__success-icon {
  font-size: 64px;
  color: #52c41a;
  animation: successPop 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-shadow: 0 0 20px rgba(82, 196, 26, 0.5), 0 0 40px rgba(82, 196, 26, 0.3), 0 0 60px rgba(82, 196, 26, 0.1);
}

.cs-confirmation__success-title {
  margin: 0 !important;
  text-align: center;
}

.cs-confirmation__success-text {
  font-size: 16px;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes successPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
