.step {
  display: flex;
  gap: 16px;
  position: relative;
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.03);
}

.stepIconWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepIcon {
  width: 32px;
  height: 32px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #10B2E1;
  font-size: 14px;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}

.connector {
  position: absolute;
  top: 32px;
  width: 2px;
  height: calc(100% + 24px);
  background: rgba(16, 178, 225, 0.2);
  transform-origin: top;
}

.stepContent {
  flex: 1;
  min-width: 0;
  position: relative;
}

.stepHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.stepTitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-weight: 500;
}

.stepTime {
  color: #10B2E1;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.stepTime svg {
  font-size: 10px;
}

.estimatedTime {
  color: rgba(255, 255, 255, 0.4);
  margin-left: 4px;
}

.stepDescription {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin: 0;
  line-height: 1.4;
}

.stepDuration {
  display: block;
  color: rgba(255, 255, 255, 0.4);
  font-size: 11px;
  margin-top: 4px;
}

.estimatedDuration {
  color: rgba(255, 255, 255, 0.3);
  margin-left: 4px;
}

/* Step States */
.completed .stepIcon {
  background: rgba(52, 199, 89, 0.1);
  color: #34C759;
}

.completed .stepTime {
  color: #34C759;
}

.completed .connector {
  background: #34C759;
}

.current .stepIcon {
  background: rgba(16, 178, 225, 0.1);
  color: #10B2E1;
}

.current .stepTitle,
.current .stepTime {
  color: #10B2E1;
}

.upcoming .stepIcon {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.4);
}

.upcoming .stepTitle,
.upcoming .stepTime,
.upcoming .stepDescription,
.upcoming .stepDuration {
  color: rgba(255, 255, 255, 0.4);
}

.progressPulse {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  background: rgba(16, 178, 225, 0.05);
  pointer-events: none;
}

/* Add these styles */
.stepBilling {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.stepBillingAmount {
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

.stepBillingAmount svg {
  color: #10B2E1;
  font-size: 10px;
}

.stepBillingAmount span {
  color: #fff;
  font-weight: 500;
}

.patientPortion {
  color: #FF9500;
  font-size: 11px;
} 