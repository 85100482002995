.cs-ai-suggest {
  border-radius: 8px;
  backdrop-filter: blur(10px);
  border: none !important;
}

.cs-ai-suggest .ant-typography {
  margin: 0 !important;
}

.cs-ai-suggest__tests {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.cs-ai-suggest__test-card {
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cs-ai-suggest__test-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.cs-ai-suggest__test-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cs-ai-suggest__timeframe {
  font-size: 14px;
}

.cs-ai-suggest__reasons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cs-ai-suggest__reason {
  font-size: 14px;
  line-height: 1.4;
}

/* Badge overrides */
.cs-ai-suggest .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.cs-ai-suggest .ant-badge-status-text {
  font-size: 14px;
}

/* Add to existing CSS */
.cs-ai-suggest__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;
}

.cs-ai-suggest__error {
  display: flex;
  justify-content: center;
  padding: 16px;
  text-align: center;
}
