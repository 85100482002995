.cs-trend-graph {
  backdrop-filter: blur(10px);
  border: none !important;
  border-radius: 12px !important;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-width: 0;
}

/* Background glow */
.cs-trend-graph::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  z-index: 0;
  filter: blur(2px);
}

/* Glass shine effect */
.cs-trend-graph::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 13px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7), 0 0 15px rgba(255, 255, 255, 0.05),
    inset 0 2px 2px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

/* Ensure content stays above glass effects */
.cs-trend-graph > * {
  position: relative;
  z-index: 2;
}

/* Override Ant Design card body padding */
.cs-trend-graph .ant-card-body {
  padding: 8px !important;
}

.cs-trend-graph__title {
  margin-bottom: 16px;
  position: relative;
  z-index: 2;
}

.cs-trend-graph__container {
  width: 100%;
  height: 300px;
  margin-top: 16px;
  position: relative;
  z-index: 2;
  min-width: 0;
  overflow: hidden;
}

/* Make chart responsive */
.cs-trend-graph .recharts-responsive-container {
  min-width: 0;
  width: 100% !important;
}

.cs-trend-graph__tooltip {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px;
  padding: 8px;
}
