.health-plan-dashboard {
  min-height: 100vh;

  padding: 16px;
  padding-bottom: 80px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.health-plan-dashboard.loading,
.health-plan-dashboard.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 96px);
}

.plans-grid {
  display: grid;
  gap: 16px;
  padding-bottom: 24px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  max-width: 100%;
  margin: 0 auto;
}

.plan-card {
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 180px;
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 12px !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.plan-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

.plan-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 13px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7), 0 0 15px rgba(255, 255, 255, 0.05),
    inset 0 2px 2px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.plan-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2), 0 0 20px rgba(16, 178, 225, 0.15);
}

.plan-card-content {
  padding: 20px;
  position: relative;
  z-index: 2;
}

.plan-card h3 {
  color: #fff;
  margin-bottom: 16px;
  font-size: 18px;
  background: linear-gradient(90deg, #10b2e1, #e91e63);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.plan-progress {
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #10b2e1, #e91e63);
  transition: width 0.5s ease;
  border-radius: 3px;
  position: relative;
}

.progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.plan-stats {
  display: grid;
  gap: 12px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  padding: 8px 12px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(16, 178, 225, 0.1);
}

.stat-item svg {
  color: #10b2e1;
}

.plan-card .delete-button {
  position: absolute;
  top: 16px;
  right: 16px;
  color: rgba(255, 255, 255, 0.45);
  z-index: 3;
  padding: 4px 8px;
  opacity: 0;
  transition: all 0.3s ease;
}

.plan-card:hover .delete-button {
  opacity: 1;
}

.plan-card .delete-button:hover {
  color: #ff4d4f;
  background: rgba(255, 77, 79, 0.1);
}
