.payment-selection-container {
  min-height: calc(100vh - 120px);
  padding: 24px 16px;
  box-sizing: border-box;
}

.payment-selection-container .payment-header {
  margin-bottom: 24px;
  text-align: center;
}

.payment-selection-container .payment-header h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 600;
}

.payment-selection-container .amount-display {
  color: #10b2e1;
  font-size: 20px;
  font-weight: 600;
  padding: 8px 16px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
  display: inline-block;
  margin-top: 8px;
}

.payment-selection-container .order-summary {
  background: #141824;
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 12px;
  margin-bottom: 24px;
  overflow: hidden;
}

.payment-selection-container .order-summary-header {
  padding: 16px 20px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(16, 178, 225, 0.15);
  transition: background-color 0.3s ease;
}

.payment-selection-container .order-summary-header:hover {
  background: rgba(16, 178, 225, 0.05);
}

.payment-selection-container .header-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.payment-selection-container .header-content h3 {
  color: #fff;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.payment-selection-container .total-preview {
  color: #10b2e1;
  font-weight: 600;
  font-size: 16px;
}

.payment-selection-container .expand-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  transition: transform 0.3s ease;
}

.payment-selection-container .expand-icon.expanded {
  transform: rotate(180deg);
}

.payment-selection-container .order-details {
  padding: 20px;
  overflow: hidden;
}

.payment-selection-container .order-items {
  margin-bottom: 16px;
}

.payment-selection-container .order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
}

.payment-selection-container .order-totals {
  margin-top: 16px;
}

.payment-selection-container .order-totals > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.payment-selection-container .subtotal {
  color: rgba(255, 255, 255, 0.7);
}

.payment-selection-container .vat {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
}

.payment-selection-container .total {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding-top: 12px;
  margin-top: 4px;
  border-top: 1px solid rgba(16, 178, 225, 0.15);
}

.payment-selection-container .item-name {
  color: rgba(255, 255, 255, 0.9);
}

.payment-selection-container .item-price {
  font-weight: 500;
  color: #10b2e1;
}

.payment-selection-container .payment-methods {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-selection-container .payment-method-card {
  background: #141824;
  border: 1px solid rgba(16, 178, 225, 0.3);
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 0 20px rgba(16, 178, 225, 0.1), inset 0 0 20px rgba(16, 178, 225, 0.05);
}

.payment-selection-container .payment-method-card:hover {
  border-color: rgba(16, 178, 225, 0.8);
  box-shadow: 0 0 25px rgba(16, 178, 225, 0.2), inset 0 0 30px rgba(16, 178, 225, 0.1);
  transform: translateY(-2px);
}

.payment-selection-container .method-icon {
  color: #10b2e1;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
}

.payment-selection-container .method-details {
  flex: 1;
}

.payment-selection-container .method-details h3 {
  color: #fff;
  font-size: 16px;
  margin: 0 0 4px 0;
  font-weight: 600;
}

.payment-selection-container .method-details p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin: 0 0 8px 0;
}

.payment-selection-container .processing-time {
  color: #10b2e1;
  font-size: 12px;
  background: rgba(16, 178, 225, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
}
