.cs-test-card.ant-card {
  background: transparent !important;
  border: 1.5px solid rgba(255, 255, 255, 0.15) !important;
  border-radius: 12px !important;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05);
}

.cs-test-card:hover {
  transform: translateY(-2px);
  border: 1.5px solid rgba(101, 210, 216, 0.4) !important;
  box-shadow: 0 0 20px rgba(101, 210, 216, 0.05), 0 0 0 1px rgba(101, 210, 216, 0.1);
}

.cs-test-card .ant-card-body {
  padding: 10px !important;
}

.cs-test-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3px;
}

.cs-test-card__title {
  font-size: 13px !important;
  font-weight: 500 !important;
  margin-right: 8px;
  flex: 1;
  line-height: 1.3 !important;
}

.cs-test-card__price {
  color: #65d2d8 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  white-space: nowrap;
}

.cs-test-card__description {
  display: block;
  font-size: 11px !important;
  margin-bottom: 6px;
  line-height: 1.3;
}

.cs-test-card__meta {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 11px;
}

.cs-test-card__meta-item {
  display: flex;
  align-items: center;
  gap: 3px;
}

.cs-test-card__meta-item svg {
  font-size: 11px;
}

/* Time element styling */
.cs-test-card__time {
  color: #4bc0c8;
}

.cs-test-card__time svg {
  color: #4bc0c8;
}

/* Specimen element styling */
.cs-test-card__specimen {
  color: #b5d777;
}

.cs-test-card__specimen svg {
  color: #b5d777;
}

.cs-test-card__chevron {
  color: #65d2d8;
  font-size: 14px;
  margin-left: auto;
  transition: transform 0.2s;
}

.cs-test-card:hover .cs-test-card__chevron {
  transform: translateX(4px);
  opacity: 0.8;
}

.cs-test-card__preparation {
  display: flex;
  gap: 8px;
  margin-left: auto;
  align-items: center;
}

.cs-test-card__prep-icon {
  font-size: 14px;
}

.cs-test-card__prep-icon.no-activity {
  color: #b5d777;
}

/* Medical Aid element styling */
.cs-test-card__medical-aid {
  color: #b5d777; /* Green color to indicate positive/covered status */
}

.cs-test-card__medical-aid svg {
  color: #b5d777;
}
