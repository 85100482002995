.caresync-container {
  min-height: 100vh;
  overflow-y: auto;
}

.caresync-tabs {
  width: 100%;
}

/* Override ant-design tab styles for dark theme */

.caresync-container .ant-tabs-nav {
  margin: 0 !important; /* Remove default margin */
  border-bottom: none !important; /* Remove bottom border */
}

.caresync-container .ant-tabs-nav::before {
  border-bottom: none !important; /* Remove default border */
}

.caresync-container .ant-tabs-tab {
  padding: 12px 16px;
  margin: 0 !important; /* Remove default margin */
}

.caresync-container .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important; /* Remove spacing between tabs */
}

.caresync-container .ant-tabs-tab:hover {
  color: #4cc9f0 !important;
}

.caresync-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4cc9f0 !important;
}

.caresync-container .ant-tabs-ink-bar {
  background: #4cc9f0;
}

/* Tab label styling */
.tab-label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-label svg {
  font-size: 16px;
}

/* CareSync brand colors for tab icons */
.caresync-tabs .ant-tabs-tab:nth-child(2) .tab-label svg {
  color: #b5d777; /* Green for Book Test */
}

.caresync-tabs .ant-tabs-tab:nth-child(3) .tab-label svg {
  color: #fdb462; /* Orange for Results */
}

.caresync-tabs .ant-tabs-tab:nth-child(4) .tab-label svg {
  color: #ef8d9c; /* Pink for Insights */
}

/* Active state colors */
.caresync-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(2) .tab-label svg,
.caresync-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(2) .ant-tabs-tab-btn {
  color: #b5d777 !important;
}

.caresync-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(3) .tab-label svg,
.caresync-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(3) .ant-tabs-tab-btn {
  color: #fdb462 !important;
}

.caresync-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(4) .tab-label svg,
.caresync-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(4) .ant-tabs-tab-btn {
  color: #ef8d9c !important;
}

/* Ink bar colors */
.caresync-tabs .ant-tabs-ink-bar {
  background: transparent !important;
  height: 3px !important;
}

.caresync-tabs .ant-tabs-nav > div:nth-child(1)[class*='ant-tabs-nav-wrap'] .ant-tabs-ink-bar {
  background: #4cc9f0 !important; /* Overview - Blue */
}

.caresync-tabs .ant-tabs-nav > div:nth-child(2)[class*='ant-tabs-nav-wrap'] .ant-tabs-ink-bar {
  background: #b5d777 !important; /* Book Test - Green */
}

.caresync-tabs .ant-tabs-nav > div:nth-child(3)[class*='ant-tabs-nav-wrap'] .ant-tabs-ink-bar {
  background: #fdb462 !important; /* Results - Orange */
}

.caresync-tabs .ant-tabs-nav > div:nth-child(4)[class*='ant-tabs-nav-wrap'] .ant-tabs-ink-bar {
  background: #ef8d9c !important; /* Insights - Pink */
}

/* Override any potential tab content borders */
.caresync-container .ant-tabs-content {
  padding: 16px;
  border-top: none !important;
}

.caresync-container .ant-tabs-tabpane {
  border: none !important;
}

/* Card-like containers within tabs */
.caresync-card {
  background-color: #243044;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

/* Primary action buttons - scoped to caresync-container */
.caresync-container .ant-btn-primary {
  background-color: #4cc9f0;
  border-color: #4cc9f0;
}

.caresync-container .ant-btn-primary:hover {
  background-color: #3db8df;
  border-color: #3db8df;
}

/* Style scrollbar for webkit browsers */
.caresync-container::-webkit-scrollbar,
.caresync-container *::-webkit-scrollbar {
  width: 8px;
}

.caresync-container::-webkit-scrollbar-thumb,
.caresync-container *::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

/* Ensure all scrollable containers have the correct background */
.caresync-container .ant-tabs-content,
.caresync-container .ant-tabs-tabpane {
  overflow-y: auto;
}

/* Handle Firefox scrollbar */
.caresync-container,
.caresync-container * {
  scrollbar-width: thin;
}

.cs-tab-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  animation: bounceIcon 2s ease-in-out infinite;
}

@keyframes bounceIcon {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
  to {
    transform: translateY(0);
  }
}
