.taskModal :global(.ant-modal-content) {
  border-radius: 8px;
}

.taskModal :global(.ant-form-item) {
  margin-bottom: 16px;
}

.datePicker {
  width: 100%;
}

.datePicker :global(.ant-picker-input) {
  background: transparent;
}

.typeDescription {
  color: #8c8c8c;
  font-size: 12px;
  margin-left: 4px;
} 