/* Container Layout */
.caresync-results {
  padding: 16px;
  background: #f5f7fa;
  min-height: 100vh;
}

.results-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Header Styling */
.results-history-header {
  margin-bottom: 20px;
  padding: 0 4px;
}

.results-history-header .ant-typography {
  color: #1c2536;
  margin: 0;
}

.results-history-header h4.ant-typography {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px !important;
}

.results-history-header .ant-typography-secondary {
  color: rgba(28, 37, 54, 0.65);
  font-size: 14px;
}

/* Search and Filter Section */
.results-filters {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 4px;
}

/* Search Bar Specific Styles */
.results-history-search.ant-input-affix-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(28, 37, 54, 0.15);
  border-radius: 8px;
  height: 40px;
  transition: all 0.3s ease;
}

.results-history-search.ant-input-affix-wrapper:hover,
.results-history-search.ant-input-affix-wrapper:focus,
.results-history-search.ant-input-affix-wrapper-focused {
  background-color: #ffffff !important;
  border-color: #1890ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.results-history-search.ant-input-affix-wrapper input.ant-input {
  background-color: transparent !important;
  color: #1c2536;
}

.results-history-search.ant-input-affix-wrapper input.ant-input::placeholder {
  color: rgba(28, 37, 54, 0.45);
}

.results-history-search .anticon {
  color: rgba(28, 37, 54, 0.45);
}

.filter-options {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

/* Results List */
.results-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
}

/* Empty State */
.results-list .ant-empty {
  margin: 32px 0;
  padding: 24px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
}

.results-list .ant-empty-description {
  color: rgba(28, 37, 54, 0.65);
}

/* Input Autofill Background Fix */
.results-history-search input:-webkit-autofill {
  -webkit-text-fill-color: #1c2536;
  box-shadow: 0 0 0px 1000px #ffffff inset;
}
