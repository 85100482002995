/* Override Ant Design components styles for this component */
.covid-wrapper .ant-card {
}

.covid-header-card {
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.covid-card {
  margin: 16px;
  border-radius: 12px !important;
}

.covid-icon {
  color: #10b2e1;
  font-size: 20px;
}

.covid-wrapper .ant-typography {
  margin: 0;
}

.covid-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.covid-subtitle {
  font-size: 14px;
}

.covid-content-wrapper {
  margin-top: 24px;
}

.covid-wrapper .ant-radio-wrapper {
  margin-right: 24px;
}

.date-section {
  margin-top: 16px;
}

.covid-wrapper .ant-typography + .ant-radio-group,
.covid-wrapper .ant-typography + .covid-datepicker {
  margin-top: 8px;
  display: block;
}

.covid-wrapper .ant-picker {
  width: 100%;
}

.covid-wrapper .covid-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 24px;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.covid-cancel-btn {
  background: rgba(239, 68, 68, 0.1) !important;
  border: 1px solid rgba(239, 68, 68, 0.2) !important;
  color: #ef4444 !important;
}

.covid-save-btn {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  color: #10b2e1 !important;
}

.covid-wrapper .ant-btn {
  height: 40px;
  border-radius: 8px;
  font-weight: 500;
}

.covid-wrapper .dark-radio-group .ant-radio-wrapper {
  color: #ffffff;
  margin-right: 24px;
}

.covid-wrapper .dark-radio-group .ant-radio-inner {
  background-color: transparent;
  border-color: #00a6e3;
}

.covid-wrapper .dark-radio-group .ant-radio-checked .ant-radio-inner {
  background-color: #00a6e3;
  border-color: #00a6e3;
}

.covid-wrapper .dark-radio-group .ant-radio-checked .ant-radio-inner::after {
  background-color: #ffffff;
}

/* Override antd Input styles */
.covid-wrapper .ant-input-affix-wrapper {
  background: #1e2330 !important;
  border: none !important;
  border-radius: 8px !important;
  color: #ffffff !important;
}

.covid-wrapper .ant-input-affix-wrapper input {
  background: transparent !important;
  color: #ffffff !important;
}

.covid-wrapper .ant-input-affix-wrapper input::placeholder {
  color: #8f9bb3 !important;
}

/* Override antd Card styles */
.covid-wrapper .ant-card-body {
  padding: 16px !important;
}

/* Card specific styles */
.covid-wrapper .covid-card {
  border-radius: 12px;
  border: none;
  margin-bottom: 12px;
}

/* Date input specific styles */
.covid-wrapper .covid-date-input {
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
}

/* Text styles */
.covid-wrapper .covid-text {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.covid-wrapper .covid-title {
  font-size: 20px;
  margin-bottom: 16px;
}
