.ql-editor {
  min-height: 300px; /* Minimum height for the editor */
  height: auto !important; /* Allow the editor to expand */
  overflow: visible !important; /* Prevent scrolling inside the editor */
}

.ql-container {
  border: none !important; /* Remove border for cleaner look */
}

.ql-toolbar {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #e8e8e8 !important;
  background-color: #fafafa;
  padding: 8px 12px !important;
}

/* Make the editor content wrap properly */
.ql-editor p {
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

/* Ensure the editor container expands with content */
.react-quill,
.quill {
  display: flex;
  flex-direction: column;
  height: auto !important;
}

/* Make placeholder text more visible */
.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6) !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 1.8 !important;
  white-space: pre-line !important;
  padding-top: 8px !important;
}
