.nutrition-plan {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.meal-suggestions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding-bottom: 80px;
}
