.mobile-macro-overview {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 76px);
  overflow: hidden;
  position: fixed;
  top: 76px;
  left: 0;
  right: 0;
  padding: 16px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;

  padding-top: 16px;
}

.action-button {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

.action-button:hover,
.action-button:focus {
  background: rgba(16, 178, 225, 0.2) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

.action-button.recipes {
  background: rgba(233, 30, 99, 0.1) !important;
  border: 1px solid #e91e63 !important;
  color: #e91e63 !important;
}

.action-button.recipes:hover,
.action-button.recipes:focus {
  background: rgba(233, 30, 99, 0.2) !important;
  border-color: #e91e63 !important;
  color: #e91e63 !important;
}

.action-button.graphs {
  background: rgba(52, 152, 219, 0.1) !important;
  border: 1px solid #3498db !important;
  color: #3498db !important;
}

.action-button.graphs:hover,
.action-button.graphs:focus {
  background: rgba(52, 152, 219, 0.2) !important;
  border-color: #3498db !important;
  color: #3498db !important;
}

.action-button:active {
  transform: scale(0.98);
}

.action-button svg {
  font-size: 16px;
}

.limits-card {
  border-radius: 16px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  position: relative;
  overflow: visible;
  max-height: none;
  height: auto;
  flex: 1;
  overflow: hidden;
}

/* Background glow effect */
.limits-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

/* Card shine effect */
.limits-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 13px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7), 0 0 15px rgba(255, 255, 255, 0.05),
    inset 0 2px 2px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.limits-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.limit-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.consumed-value {
  display: block;
  font-size: 12px;
  color: #ffffff80;
  margin-bottom: 4px;
}

.limit-label {
  width: 70px;
  color: #fff;
}

.limit-value {
  width: 50px;
  text-align: right;
  color: #fff;
}

.limit-header {
  display: none;
}

/* Common styles for both view and edit modes */
.limits-content-view,
.limits-content-edit {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0;
  background: transparent;
  overflow: visible;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 8px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Common limit item styles for both modes */
.limits-content-view .limit-item,
.limits-content-edit .limit-item {
  display: grid;
  grid-template-columns: 75px 1fr 65px;
  gap: 8px;
  align-items: center;
  height: 36px;
  margin: 0;
}

/* Progress container adjustments */
.progress-container {
  position: relative;
  flex: 1;
  margin-top: 8px;
  width: 100%;
}

/* Common label styles */
.limit-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
}

/* Common value styles */
.limit-value {
  font-size: 13px;
  min-width: 50px;
  text-align: right;
}

/* Slider container spacing */
.slider-container {
  width: 100%;
  padding: 4px 0;
  touch-action: pan-y pinch-zoom;
}

/* Fixed card height */
.limits-card {
  height: 320px;
}

/* Add these styles for the edit/save button */
.edit-save-button {
  width: 60px !important;
  height: 36px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

.edit-save-button:hover,
.edit-save-button:focus {
  background: rgba(16, 178, 225, 0.2) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

.edit-save-button.editing {
  background: rgba(233, 30, 99, 0.1) !important;
  border: 1px solid #e91e63 !important;
  color: #e91e63 !important;
}

.edit-save-button.editing:hover,
.edit-save-button.editing:focus {
  background: rgba(233, 30, 99, 0.2) !important;
  border-color: #e91e63 !important;
  color: #e91e63 !important;
}

/* Hide scrollbar for Chrome/Safari/Opera */
.limits-content-view::-webkit-scrollbar,
.limits-content-edit::-webkit-scrollbar {
  display: none;
}
