.leaderboard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #141824;
  position: relative;
  overflow: hidden;
  padding-top: 24px;
}

.leaderboard-header {
  padding: 16px 16px 0;
  background: transparent;
  border-bottom: 1px solid rgba(16, 178, 225, 0.15);
  position: sticky;
  top: 24px;
  z-index: 10;
  backdrop-filter: none;
}

/* Stats Cards */
.leaderboard-container .leaderboard-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  background: transparent;
}

.leaderboard-container .stat-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1.5px solid rgba(16, 178, 225, 0.15);
  border-radius: 12px;
  padding: 12px;
  text-align: center;
}

.leaderboard-container .stat-card .stat-value {
  color: #10b2e1;
  font-size: 20px;
  font-weight: 700;
  text-shadow: 0 0 12px rgba(16, 178, 225, 0.3);
  margin-bottom: 4px;
}

.leaderboard-container .stat-card .stat-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Custom Filter Buttons */
.leaderboard-container .leaderboard-filters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0;
  padding: 0 16px;
  background: transparent;
}

.leaderboard-container .filter-row {
  display: flex;
  gap: 8px;
  width: 100%;
}

.leaderboard-container .filter-button {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  height: 36px;
  background: rgba(16, 178, 225, 0.05) !important;
  border: 1.5px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 8px !important;
  color: rgba(255, 255, 255, 0.6) !important;
  transition: all 0.2s ease !important;
  padding: 0 12px !important;
  font-size: 13px !important;
}

.leaderboard-container .filter-button.active {
  background: rgba(16, 178, 225, 0.15) !important;
  border-color: rgba(16, 178, 225, 0.3) !important;
  color: #10b2e1 !important;
  font-weight: 500 !important;
}

.leaderboard-container .filter-button:hover:not(.active) {
  background: rgba(16, 178, 225, 0.1) !important;
  border-color: rgba(16, 178, 225, 0.25) !important;
  transform: translateY(-1px);
}

/* Scrollable Content */
.leaderboard-container .leaderboard-content {
  flex: 1;
  overflow-y: auto;
  padding: 16px 16px 140px;
}

/* Table Styling */
.leaderboard-container .leaderboard-table {
  width: 100%;
  border-spacing: 0 8px;
}

.leaderboard-container .leaderboard-table tr {
  cursor: pointer;
  transition: all 0.2s ease;
}

.leaderboard-container .leaderboard-table td {
  padding: 12px;
  background: rgba(16, 178, 225, 0.02);
  border: 1.5px solid rgba(16, 178, 225, 0.15);
}

/* Player Info */
.leaderboard-container .player-cell {
  padding-right: 8px;
}

.leaderboard-container .player-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.leaderboard-container .player-name {
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-weight: 500;
}

.leaderboard-container .streak {
  color: #10b2e1;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.leaderboard-container .streak svg {
  color: #e91e63;
}

/* Points and Level */
.leaderboard-container .stats-cell {
  white-space: nowrap;
  text-align: right;
}

.leaderboard-container .stats-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.leaderboard-container .points-value {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 600;
}

.leaderboard-container .level-badge {
  display: inline-flex;
  align-items: center;
  background: linear-gradient(45deg, #10b2e1, #1e90ff);
  padding: 2px 8px;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 0 12px rgba(16, 178, 225, 0.3);
}

/* Trophy and Rank Styling */
.leaderboard-container .rank-cell {
  width: 40px;
  text-align: center;
}

.leaderboard-container .rank-badge {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
}

/* Trophy Icons */
.leaderboard-container .trophy-icon {
  font-size: 20px;
}

.leaderboard-container .rank-badge.top-1 .trophy-icon {
  color: #ffd700;
  filter: drop-shadow(0 0 8px rgba(255, 215, 0, 0.4));
}

.leaderboard-container .rank-badge.top-2 .trophy-icon {
  color: #c0c0c0;
  filter: drop-shadow(0 0 8px rgba(192, 192, 192, 0.4));
}

.leaderboard-container .rank-badge.top-3 .trophy-icon {
  color: #cd7f32;
  filter: drop-shadow(0 0 8px rgba(205, 127, 50, 0.4));
}

/* Remove old rank badge styles */
.leaderboard-container .rank-badge {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Back Button */
.leaderboard-container .leaderboard-back-button {
  position: fixed;
  bottom: 84px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1.5px solid rgba(16, 178, 225, 0.25) !important;
  height: 44px !important;
  padding: 0 24px !important;
  border-radius: 22px !important;
  color: #10b2e1 !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  transition: all 0.2s ease !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
  z-index: 100;
  width: calc(100% - 32px) !important;
  max-width: 200px;
  justify-content: center !important;
}

.leaderboard-container .leaderboard-back-button:hover {
  background: rgba(16, 178, 225, 0.15) !important;
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3) !important;
}
