/* Modal Wrapper */
.test-info-modal-wrapper .ant-modal-content {
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(20px) saturate(180%) !important;
  -webkit-backdrop-filter: blur(20px) saturate(180%) !important;
  border-radius: 16px !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15) !important;
  padding: 24px !important;
}

/* Modal Background Overlay */
.test-info-modal-wrapper .ant-modal-mask {
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.2) !important;
}

.test-info-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  z-index: 1;
}

.test-info-content::before {
  content: '';
  position: absolute;
  inset: -24px;
  background: linear-gradient(180deg, rgba(40, 44, 52, 0.4), rgba(40, 44, 52, 0.2));
  z-index: -1;
  border-radius: 16px;
}

.test-info-content h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.test-info-title {
  color: #7ee7ed;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.test-info-text {
  font-size: 14px;
  line-height: 1.6;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 400;
}

/* Close Button */
.test-info-modal-wrapper .ant-modal-close {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.test-info-modal-wrapper .ant-modal-close:hover {
  color: #ffffff;
}

@media (max-width: 480px) {
  .test-info-modal-wrapper {
    max-width: 90vw !important;
    margin: 0 auto;
  }

  .test-info-modal-wrapper .ant-modal-content {
    padding: 20px !important;
  }

  .test-info-content::before {
    inset: -20px;
  }
}
