.mobile-booking-results {
  padding: 16px;

  min-height: 100vh;
  position: relative;
  padding-bottom: 70px;
}

.results-header {
  margin-bottom: 24px;
  text-align: center;
}

.results-header h2 {
  font-size: 24px;
  margin-bottom: 8px;
}

.results-header p {
  font-size: 16px;
}

.doctors-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 140px;
}

.doctor-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.2);
  border-radius: 12px;
  padding: 16px;
}

.doctor-info {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}

.doctor-avatar {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.3);
}

.doctor-details h3 {
  margin: 0;
  font-size: 18px;
}

.doctor-details p {
  margin: 4px 0 0;
  font-size: 14px;
}

.time-period {
  margin-bottom: 16px;
}

.time-period h4 {
  color: rgba(16, 178, 225, 0.9);
  margin-bottom: 8px;
  font-size: 14px;
}

.time-slots {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 8px !important;
  width: 100% !important;
}

.time-slot-button {
  width: 100% !important;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: rgba(16, 178, 225, 0.05) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
}

.time-slot-button:hover {
  border-color: #10b2e1 !important;
}

.time-icon {
  font-size: 12px;
}

/* Button container to ensure proper positioning */
.button-container {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  padding: 0 16px;
  z-index: 1000;
}

.confirm-button {
  width: 100% !important;
  height: 48px !important;
  background: #10b2e1 !important;
  border: none !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.confirm-button:hover {
  opacity: 0.9 !important;
}

.time-slots-panel .ant-collapse-header {
  border: none !important;
}
