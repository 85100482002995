.completion-modal .ant-modal-content {
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 193, 7, 0.2);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  padding: 28px 20px;
}

.completion-modal .ant-modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.completion-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.completion-modal h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 0.5px;
  animation: slideDown 0.5s ease-out;
}

.completion-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.completion-icon {
  font-size: 32px;
  display: inline-block;
  animation: bounce 1s ease-out infinite;
  margin-top: -4px;
}

.completion-stars {
  margin: 20px 0;
  display: flex;
  gap: 8px;
  justify-content: center;
  height: 48px;
}

.completion-stars span {
  animation: starBurst 0.5s ease-out;
  transform-origin: center;
  display: inline-block;
}

.points-earned {
  font-size: 28px;
  margin: 20px 0;
  font-weight: 500;
  background: linear-gradient(to right, rgba(255, 193, 7, 0.05), rgba(255, 193, 7, 0.15), rgba(255, 193, 7, 0.05));
  padding: 16px 32px;
  border-radius: 12px;
  border: 1px solid rgba(255, 193, 7, 0.2);
  animation: slideUp 0.5s ease-out;
  position: relative;
  min-width: 180px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.completion-modal p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-bottom: 0;
  animation: fadeIn 0.5s ease-out;
  margin-top: 8px;
}

.completion-modal p span {
  font-size: 20px;
  margin-left: 4px;
  display: inline-block;
  animation: bounce 1s ease-out infinite;
}

.completion-modal .ant-btn {
  margin-top: 20px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 24px;
  border-radius: 20px;
  background: linear-gradient(to right, #ffb300, #ffc107, #ffb300);
  border: none;
  box-shadow: 0 4px 12px rgba(255, 193, 7, 0.3);
  animation: slideUp 0.5s ease-out 0.2s backwards;
  position: relative;
  overflow: hidden;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes starBurst {
  0% {
    transform: scale(0) rotate(-45deg);
    opacity: 0;
  }
  50% {
    transform: scale(1.4) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}
