.payment-cancelled-container {
  min-height: calc(100vh - 120px);
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.payment-cancelled-container .cancelled-card {
  border: 1px solid rgba(255, 189, 0, 0.4);
  border-radius: 12px;
  padding: 32px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), 0 0 15px rgba(255, 189, 0, 0.15), inset 0 0 20px rgba(255, 189, 0, 0.05);
  margin-top: -180px;
  animation: warningGlow 3s ease-in-out infinite;
}

/* Animated warning icon */
.payment-cancelled-container .warning-icon-wrapper {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 24px;
  background: transparent;
}

.payment-cancelled-container .warning-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #ffbd00;
  border-radius: 50%;
  animation: warningCircle 0.8s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  opacity: 0;
  background: transparent;
}

.payment-cancelled-container .warning-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #ffbd00;
  animation: warningBang 0.8s cubic-bezier(0.65, 0, 0.45, 1) 0.2s forwards;
  opacity: 0;
}

@keyframes warningGlow {
  0% {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), 0 0 15px rgba(255, 189, 0, 0.15), inset 0 0 20px rgba(255, 189, 0, 0.05);
  }
  50% {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), 0 0 20px rgba(255, 189, 0, 0.25), inset 0 0 25px rgba(255, 189, 0, 0.1);
  }
  100% {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), 0 0 15px rgba(255, 189, 0, 0.15), inset 0 0 20px rgba(255, 189, 0, 0.05);
  }
}

@keyframes warningCircle {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes warningBang {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.payment-cancelled-container .cancelled-card h2 {
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: 600;
}

.payment-cancelled-container .cancel-message {
  font-size: 14px;
  margin: 0 0 8px 0;
  line-height: 1.5;
  padding: 0;
  background: transparent !important;
  border: none !important;
  text-align: center;
  font-weight: normal;
}

.payment-cancelled-container .action-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 24px;
  background: transparent !important;
}

.payment-cancelled-container .primary-button {
  background: transparent !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  height: 40px;
  padding: 0 24px !important;
  font-weight: 600 !important;
  transition: all 0.3s ease !important;
  box-shadow: none !important;
}

.payment-cancelled-container .secondary-button {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-shadow: none !important;
}
