.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  padding: 16px;
}

.approvalRequest {
  border-radius: 16px;
  width: 100%;
  max-height: 85vh;
  padding: 20px;
  position: relative;
  overflow-y: auto;
  margin: auto;
  animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.approvalHeader {
  margin-bottom: 16px;
  text-align: center;
}

.approvalHeader h3 {
  color: #fff;
  font-size: 16px;
  margin: 0 0 4px 0;
}

.approvalHeader p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  margin: 0;
}

.approvalDetails {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
}

.approvalItem {
  padding: 12px;
}

.approvalItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.approvalItemHeader h4 {
  color: #fff;
  font-size: 15px;
  margin: 0;
}

.approvalItemCost {
  color: #ff9500;
  font-size: 15px;
  font-weight: 500;
}

.approvalItemDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.approvalItemDetail {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  padding: 4px 0;
}

.approvalActions {
  display: flex;
  gap: 10px;
}

.approvalButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 12px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s;
}

.approvalButton:active {
  transform: scale(0.98);
}

.approve {
  background: #34c759;
  color: white;
}

.decline {
  background: rgba(255, 59, 48, 0.1);
  border: 1px solid rgba(255, 59, 48, 0.2);
  color: #ff3b30;
}

.approvalDescription {
  color: rgba(255, 255, 255, 0.9);
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 12px;
}

.approvalDescription p {
  margin: 3px 0;
}

.approvalInfo {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 10px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
  margin-bottom: 12px;
}

.approvalInfo svg {
  color: #10b2e1;
  font-size: 14px;
  margin-top: 2px;
}

.approvalInfo p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 13px;
  margin: 0;
  flex: 1;
  line-height: 1.4;
}

.approvalDuration {
  display: flex;
  gap: 8px;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  margin-bottom: 12px;
}

.approvalDuration svg {
  color: #10b2e1;
  font-size: 12px;
}

.approvalDuration p {
  margin: 0;
}
