/* Enhanced styling for medical graphs */

.recharts-wrapper {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  margin: 0 auto;
}

.recharts-surface {
  overflow: visible;
}

.recharts-cartesian-axis-line {
  stroke: rgba(0, 0, 0, 0.15);
  stroke-width: 1.5px;
}

.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
  stroke: rgba(0, 0, 0, 0.05);
}

.recharts-legend-item-text {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.recharts-legend-item {
  margin-right: 12px !important;
}

/* Legend positioning */
.recharts-legend-wrapper {
  bottom: 0 !important;
  padding-top: 10px !important;
  margin-top: 10px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.recharts-tooltip-wrapper {
  z-index: 1000;
}

.recharts-default-tooltip {
  border: none !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08);
}

.recharts-active-dot {
  transition: r 0.2s ease;
}

.recharts-line-curve {
  transition: stroke-width 0.2s ease;
}

/* Ensure dots are properly positioned */
.recharts-dot {
  transform-origin: center center;
}

/* Ensure proper spacing for the first and last points */
.recharts-line:first-child .recharts-curve {
  transform: translateX(4px);
}

.recharts-line:last-child .recharts-curve {
  transform: translateX(-4px);
}

/* Hover effects */
.recharts-line:hover .recharts-line-curve {
  stroke-width: 3px;
}

/* Make sure labels are always on top */
.recharts-data-label {
  z-index: 10;
  pointer-events: none; /* Prevent labels from interfering with hover */
  filter: drop-shadow(0px 0px 3px white) drop-shadow(0px 0px 2px white); /* Enhanced white outline */
  font-weight: 600 !important;
  text-shadow: 0px 0px 3px white, 0px 0px 1px white; /* Add text shadow for better visibility */
}

/* Specific styling for blood pressure labels */
.systolic-label {
  fill-opacity: 1 !important;
  font-weight: 600 !important;
}

.diastolic-label {
  fill-opacity: 1 !important;
  font-weight: 600 !important;
}

/* Ensure dots don't cover labels */
.recharts-dot-custom {
  z-index: 5;
}

.recharts-active-dot-custom {
  z-index: 6;
}

/* Ensure tooltip is always on top */
.recharts-tooltip-wrapper {
  z-index: 1000 !important;
  pointer-events: none;
}

/* Improve visibility of data points */
.recharts-dot circle {
  stroke-width: 1.5px;
}

.recharts-active-dot circle {
  stroke-width: 2px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .recharts-legend-wrapper {
    font-size: 12px;
  }
} 