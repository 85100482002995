.metric-card.ant-card {
  background: #141824 !important;
  border: 1.5px solid rgba(16, 178, 225, 0.25) !important;
  border-radius: 12px !important;
}

.metric-card .metric-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.metric-card .metric-icon {
  color: #10b2e1;
  font-size: 20px;
  filter: drop-shadow(0 0 6px rgba(16, 178, 225, 0.3));
}

.metric-card h3 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.metric-card .metric-content {
  margin-bottom: 16px;
}

.metric-card .current-value {
  display: flex;
  align-items: baseline;
  gap: 4px;
  margin-bottom: 8px;
}

.metric-card .value {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.metric-card .unit {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.metric-card .target-value {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
}

.metric-card .device-status {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.metric-card .sync-icon {
  color: #10b2e1;
  font-size: 12px;
}

.metric-card .metric-actions {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.metric-card .metric-actions .ant-btn {
  flex: 1;
  height: 36px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.25) !important;
  color: #10b2e1 !important;
  transition: all 0.2s ease;
}

.metric-card .metric-actions .ant-btn:hover {
  background: rgba(16, 178, 225, 0.15) !important;
  border-color: rgba(16, 178, 225, 0.4) !important;
  transform: translateY(-1px);
}

.metric-card .metric-actions .ant-btn svg {
  font-size: 14px;
  margin-right: 8px;
} 