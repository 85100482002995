.mobile-macro-overview {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.action-button {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

.action-button:hover,
.action-button:focus {
  background: rgba(16, 178, 225, 0.2) !important;
  border-color: #10b2e1 !important;
  color: #10b2e1 !important;
}

.action-button.recipes {
  background: rgba(233, 30, 99, 0.1) !important;
  border: 1px solid #e91e63 !important;
  color: #e91e63 !important;
}

.action-button.recipes:hover,
.action-button.recipes:focus {
  background: rgba(233, 30, 99, 0.2) !important;
  border-color: #e91e63 !important;
  color: #e91e63 !important;
}

.action-button.graphs {
  background: rgba(52, 152, 219, 0.1) !important;
  border: 1px solid #3498db !important;
  color: #3498db !important;
}

.action-button.graphs:hover,
.action-button.graphs:focus {
  background: rgba(52, 152, 219, 0.2) !important;
  border-color: #3498db !important;
  color: #3498db !important;
}

.action-button:active {
  transform: scale(0.98);
}

.action-button svg {
  font-size: 16px;
}

.limits-card {
  border-radius: 16px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  position: relative;
  overflow: visible;
  max-height: 360px;
  height: 320px;
}

/* Background glow effect */
.limits-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

/* Card shine effect */
.limits-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 13px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7), 0 0 15px rgba(255, 255, 255, 0.05),
    inset 0 2px 2px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.limits-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.limit-item {
  display: grid;
  grid-template-columns: 75px 1fr 45px;
  gap: 12px;
  align-items: center;
  margin-top: 18px;
  width: 100%;
}

.limit-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

.limit-value {
  color: #10b2e1 !important;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}

.progress-bar {
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin-top: 16px;
  position: relative;
  width: 100%;
}

.progress {
  height: 100%;
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0;
}

.progress.protein {
  background: #ff8000;
}
.progress.fats {
  background: #2ecc71;
}
.progress.carbs {
  background: #3498db;
}
.progress.sugar {
  background: #ff1493;
}
.progress.calories {
  background: #f1c40f;
}

.limit-header {
  display: none;
}

.progress-container {
  position: relative;
  flex: 1;
  margin-top: 8px;
  width: 100%;
}

.consumed-value {
  position: absolute;
  top: -10px;
  left: 0;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1;
}

/* Common styles for both view and edit modes */
.limits-content-view,
.limits-content-edit {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0;
  background: transparent;
  overflow: visible;
}

/* Common limit item styles for both modes */
.limits-content-view .limit-item,
.limits-content-edit .limit-item {
  display: grid;
  grid-template-columns: 75px 1fr 65px;
  gap: 8px;
  align-items: center;
  height: 36px;
  margin: 0;
}

/* Progress container adjustments */
.progress-container {
  position: relative;
  flex: 1;
  margin-top: 8px;
  width: 100%;
}

/* Common label styles */
.limit-label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
}

/* Common value styles */
.limit-value {
  font-size: 13px;
  min-width: 50px;
  text-align: right;
}

/* Slider container spacing */
.slider-container {
  width: 100%;
  padding: 4px 0;
  touch-action: pan-y pinch-zoom;
}

/* Fixed card height */
.limits-card {
  height: 320px;
}
