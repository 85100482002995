.medication-card {
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid #ff4d4f;
  transition: border-color 0.3s ease;
  margin-bottom: 16px;
}

.medication-card.medication-card-complete {
  border-color: #d9d9d9;
}

.medication-card .ant-card {
  border-radius: 8px;
  border: none;
  background: #fff;
}

.medication-card .medication-field {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 8px 12px;
  min-height: 40px;
  background: white;
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: min-content;
  overflow: visible;
}

.medication-card .medication-field.medication-field-incomplete {
  border-color: #ff4d4f;
}

.medication-card .medication-field-selected {
  background: #e6f7ff;
  color: #1890ff;
  padding: 4px 8px;
  border-radius: 16px;
  border: 1px solid #91caff;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  white-space: nowrap;
}

.medication-card .medication-field-selected .anticon {
  font-size: 12px;
}

.medication-card .medication-field-options {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: fit-content;
}

.medication-card .medication-field-options .ant-btn {
  height: 24px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
  min-width: fit-content;
}

.medication-card .medication-field .ant-input-number {
  height: 24px;
  border-radius: 4px;
}

.medication-card .medication-field .ant-input-number-input {
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
}

.medication-card .estimated-quantity {
  background-color: #ffffff;
  cursor: default;
}

.medication-card .medication-item {
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 4px 0;
}

.medication-card .medication-item:hover {
  background-color: #e6f7ff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-color: #1890ff;
  transform: translateY(-1px);
}

.medication-card .medication-item:hover .edit-text {
  opacity: 1;
  color: #1890ff !important;
  font-weight: 500;
}

.medication-card .edit-text {
  opacity: 0.7;
  transition: all 0.2s ease;
  padding: 4px 8px;
  border-radius: 4px;
}

.medication-card .medication-item:hover .edit-text {
  background-color: #e6f7ff;
}

/* Scoped Ant Design overrides */
.medication-card .ant-radio-group .ant-radio-button-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
  margin-right: 4px;
  height: 32px;
  padding: 4px 15px;
  background: white;
  color: rgba(0, 0, 0, 0.88);
}

.medication-card .ant-radio-group .ant-radio-button-wrapper:before {
  display: none;
}

.medication-card .ant-radio-group .ant-radio-button-wrapper-checked {
  border: 1px solid #1890ff !important;
  color: #1890ff !important;
  background: #e6f4ff !important;
}

.medication-card .ant-radio-group .ant-radio-button-wrapper:hover {
  color: #1890ff;
}

.medication-card .field-container {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: fit-content;
}

.medication-card .field-label {
  margin-bottom: 4px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  white-space: nowrap;
}

.medication-card .dosage-instructions-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  margin-bottom: 16px;
  width: 100%;
  align-items: flex-start;
}

.medication-card .additional-fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: flex-start;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
}

.medication-field-incomplete {
  border-color: #ff4d4f !important;
}

.medication-field-incomplete .ant-select-selector {
  border-color: #ff4d4f !important;
}

.medication-field-incomplete .ant-input {
  border-color: #ff4d4f !important;
}

.dosage-entry {
  padding: 16px;
  border-radius: 4px;
  background-color: #fafafa;
  margin-bottom: 16px;
}

.dosage-entry:last-child {
  margin-bottom: 0;
}

.dosage-entry-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.dosage-entry-header h4 {
  margin: 0;
  color: #1890ff;
}

.dosage-entry-content {
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
} 