.daily-tasks-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tasks-content {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  padding-bottom: 180px;
}

.time-slot {
  margin-bottom: 24px;
}

.time-slot:last-child {
  margin-bottom: 0;
}

.time-category {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.tasks-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Ensure proper scrollbar styling */
.tasks-content::-webkit-scrollbar {
  width: 8px;
}

.tasks-content::-webkit-scrollbar-track {
  background: rgba(16, 178, 225, 0.05);
  border-radius: 4px;
}

.tasks-content::-webkit-scrollbar-thumb {
  background: rgba(16, 178, 225, 0.2);
  border-radius: 4px;
}

.tasks-content::-webkit-scrollbar-thumb:hover {
  background: rgba(16, 178, 225, 0.3);
} 