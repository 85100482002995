.tasks-group {
  display: grid;
  gap: 16px;
}

.task-card {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 12px !important;
  transition: all 0.3s ease;
}

.task-card.priority-high {
  border-left: 3px solid #e91e63 !important;
}

.task-card.priority-medium {
  border-left: 3px solid #10b2e1 !important;
}

.task-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(16, 178, 225, 0.2);
}

.task-time {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
}

.task-main {
  display: flex;
  gap: 16px;
}

.task-icon {
  font-size: 24px;
  color: #10b2e1;
  flex-shrink: 0;
}

.task-content {
  flex: 1;
}

.task-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.task-header h4 {
  color: #fff;
  margin: 0;
  font-size: 16px;
}

.task-meta {
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.task-points {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 4px;
  font-size: 13px;
  color: #10b2e1;
}

.task-points svg {
  font-size: 12px;
}

.task-content p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  margin: 0 0 16px 0;
}

.capture-btn {
  width: 100%;
  height: 36px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.task-card.priority-high .capture-btn {
  color: #e91e63 !important;
  border-color: rgba(233, 30, 99, 0.3) !important;
}

.task-card.priority-high .capture-btn:hover {
  background: rgba(233, 30, 99, 0.1) !important;
  border-color: rgba(233, 30, 99, 0.4) !important;
}

.task-card.priority-medium .capture-btn {
  color: #10b2e1 !important;
  border-color: rgba(16, 178, 225, 0.3) !important;
}

.task-card.priority-medium .capture-btn:hover {
  background: rgba(16, 178, 225, 0.1) !important;
  border-color: rgba(16, 178, 225, 0.4) !important;
}

.task-card .capture-btn.completed {
  color: #52c41a !important;
  border-color: rgba(82, 196, 26, 0.3) !important;
  cursor: default;
}

/* Time category headers */
.time-category {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
  padding-left: 8px;
  border-left: 2px solid rgba(16, 178, 225, 0.4);
}

.time-slot {
  margin-bottom: 32px;
}

.time-slot:last-child {
  margin-bottom: 0;
}
