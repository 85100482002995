.scriptHistoryTable {
  position: relative;
  min-height: 400px;
}

.scriptHistoryTable :global(.ant-spin-nested-loading) {
  position: static;
}

.scriptHistoryTable :global(.ant-spin-container) {
  position: static;
}

.scriptHistoryTable :global(.ant-spin) {
  max-height: none;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} 