.mobile-healthy-alternatives {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  padding: 76px 0 0;
  overflow: hidden;
}

/* Create scrollable container for recipes */
.recipes-container {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  padding-bottom: 130px;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

.list-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 8px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.list-header .back-button {
  display: none;
}

.recipe-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  position: sticky;
  top: 0;
  padding: 16px;
  z-index: 10;
}

.recipe-header h2 {
  margin: 0;
  font-size: 20px;
}

.search-input {
  flex: 1;
  border: 1px solid rgba(16, 178, 225, 0.3) !important;
  border-radius: 24px !important;
  padding: 10px 16px !important;
  height: 44px !important;
  font-size: 15px !important;
  transition: all 0.3s ease;
}

/* Focus state */
.search-input:focus-within {
  border-color: #10b2e1 !important;
  box-shadow: 0 0 0 2px rgba(16, 178, 225, 0.1) !important;
}

.recipes-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.recipe-card {
  border: 1px solid rgba(16, 178, 225, 0.3) !important;
  border-radius: 12px !important;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 16px;
  margin-bottom: 12px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 0 1px rgba(16, 178, 225, 0.3);
}

/* Shiny border effect */
.recipe-card::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 12px;
  background: linear-gradient(
    145deg,
    rgba(16, 178, 225, 0.5) 0%,
    rgba(16, 178, 225, 0.1) 20%,
    rgba(16, 178, 225, 0.05) 40%,
    rgba(16, 178, 225, 0.1) 60%,
    rgba(16, 178, 225, 0.3) 100%
  );
  z-index: 0;
  pointer-events: none;
  opacity: 0.3;
}

/* Card content container */
.recipe-card::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 11px;
  z-index: 0;
}

.recipe-card > * {
  position: relative;
  z-index: 1;
}

.recipe-card:hover {
  border-color: #10b2e1 !important;
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 0 2px rgba(16, 178, 225, 0.5);
}

.recipe-card:hover::before {
  opacity: 0.5;
}

.recipe-card h3 {
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 500;
}

.recipe-card p {
  margin: 0 0 8px;
  font-size: 14px;
  line-height: 1.4;
}

.recipe-date {
  color: rgba(16, 178, 225, 0.8);
  font-size: 12px;
  display: block;
  margin-top: 8px;
}

.ingredients-list,
.preparation-list {
  font-size: 14px;
  padding-left: 20px;
  margin: 0;
}

.ingredients-list li,
.preparation-list li {
  margin-bottom: 8px;
}

.save-button {
  width: 100%;
  height: 48px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  background: #10b2e1;
}

.bottom-button-container {
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  padding: 16px;
  background: transparent !important;
  z-index: 1000;
  display: flex;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.bottom-button-container .bottom-back-button {
  pointer-events: auto;
}

.bottom-back-button {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: transparent !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  box-shadow: none !important;
}

.bottom-back-button:hover {
  background: rgba(16, 178, 225, 0.1) !important;
}

.make-this-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  border-radius: 20px;
  padding: 4px 12px !important;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 2;
  height: 28px !important;
  line-height: 1 !important;
}

.make-this-button:hover {
  background: rgba(16, 178, 225, 0.2) !important;
  transform: translateY(-1px);
}

/* Adjust icon size if needed */
.make-this-button svg {
  font-size: 12px !important;
}

/* Remove any background overlays */
.mobile-healthy-alternatives::after,
.mobile-healthy-alternatives::before {
  display: none !important;
}

/* Recipe details view styling */
.recipe-details {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.recipe-details-container {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  padding-bottom: 130px;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

/* Update recipe card styles for details view */
.recipe-details .recipe-card {
  margin-bottom: 16px;
}
