.cs-test-promo {
  margin: 0 -16px;
  padding: 0 16px;
}

.cs-test-promo__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.cs-test-promo__header .ant-typography {
  margin: 0 !important;
}

.cs-test-promo__header .ant-btn-link {
  padding: 0;
  height: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #10b2e1 !important;
  font-weight: 500;
}

.cs-test-promo__carousel-wrapper {
  position: relative;
  margin: 0 16px;
}

.cs-test-promo__carousel {
  margin: 0 -8px;
}

.cs-test-promo__carousel .slick-list {
  margin: 0 -8px;
}

.cs-test-promo__slide {
  padding: 0 8px;
}

.cs-test-promo__card {
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  height: 160px;
}

/* Override Ant Design Card body padding */
.cs-test-promo__card .ant-card-body {
  padding: 10px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cs-test-promo__icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 4px;
}

.cs-test-promo__content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  justify-content: space-between;
}

.cs-test-promo__name {
  font-size: 14px !important;
  line-height: 1.1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1px;
}

.cs-test-promo__description {
  font-size: 11px !important;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
  margin: 0;
}

.cs-test-promo__price {
  font-size: 16px !important;
  color: #10b2e1 !important;
  margin-top: 1px;
  font-weight: 600 !important;
}

.cs-test-promo__book-btn {
  margin-top: 3px;
  height: 26px !important;
  line-height: 26px !important;
  padding: 0 8px !important;
}

/* Carousel Navigation */
.cs-test-promo .slick-prev,
.cs-test-promo .slick-next {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  backdrop-filter: blur(10px);
  z-index: 2;
  transition: all 0.2s ease;
}

.cs-test-promo .slick-prev {
  left: -16px;
}

.cs-test-promo .slick-next {
  right: -16px;
}

.cs-test-promo .slick-prev:before,
.cs-test-promo .slick-next:before {
  font-size: 16px;
  line-height: 1;
}

.cs-test-promo .slick-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
