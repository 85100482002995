.section-header-card {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  padding: 16px;
  margin-bottom: 24px;
}

.section-header-icon {
  color: #10b2e1;
  font-size: 20px;
}

.section-header-title {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.section-header-subtitle {
  font-size: 14px;
  display: block;
}
