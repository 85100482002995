.runningTotal {
  position: fixed;
  bottom: 80px;
  left: 20px;
  right: 20px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  border-radius: 12px;
  padding: 12px 16px;
  z-index: 1001;
  backdrop-filter: blur(8px);
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.runningTotalHeader {
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin-bottom: 8px;
}

.runningTotalHeader svg {
  color: #10B2E1;
  font-size: 12px;
}

.runningTotalContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.runningTotalAmount {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.runningTotalAmount span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.runningTotalSplit {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
}

.medicalAidPortion {
  color: #34C759;
}

.patientPortion {
  color: #FF9500;
} 