.booking-confirmation {
  padding: 16px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.confirmation-header {
  text-align: center;
  margin-top: 32px;
}

.success-icon {
  width: 64px;
  height: 64px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
  color: #10b2e1;
  font-size: 24px;
}

.confirmation-header h2 {
  font-size: 24px;
  margin-bottom: 8px;
}

.confirmation-header p {
  font-size: 16px;
}

.booking-details {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.detail-icon {
  color: #10b2e1;
  font-size: 20px;
  width: 24px;
}

.detail-content {
  flex: 1;
}

.detail-content label {
  font-size: 14px;
  display: block;
  margin-bottom: 4px;
}

.detail-content p {
  font-size: 16px;
  margin: 0;
}

.confirmation-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: auto;
  padding-bottom: 24px;
}

.action-button {
  height: 48px !important;
  font-size: 16px !important;
  width: 100% !important;
}

.action-button.secondary {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  color: #10b2e1 !important;
}
