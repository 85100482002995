.cs-health-insights {
  width: 100%;
}

.cs-health-insights__grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
}

.cs-health-insights__main-metric {
  width: 100%;
  margin-bottom: 32px;
}

.cs-health-insights__main-metric .cs-health-insights__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
}

.cs-health-insights__main-metric .ant-progress-circle {
  width: 120px !important;
  height: 120px !important;
  margin-top: -8px;
}

.cs-health-insights__score-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.cs-health-insights__score-icon {
  font-size: 24px;
  color: #65d2d8;
  margin-bottom: 4px;
}

.cs-health-insights__score-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
}

.cs-health-insights__score-value span {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cs-health-insights__score-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
  position: relative;
  top: -16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cs-health-insights__score-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.cs-health-insights__score-status {
  color: #65d2d8 !important;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.cs-health-insights__score-completion {
  font-size: 14px;
  text-align: center;
}

.cs-health-insights__secondary-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.cs-health-insights__vertical-metrics {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cs-health-insights__vertical-metrics .cs-health-insights__card {
  width: 100%;
}

.cs-health-insights__card,
.cs-health-insights__category,
.cs-health-insights__test {
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  transition: all 0.3s ease;
}

.cs-health-insights__card:hover,
.cs-health-insights__category:hover,
.cs-health-insights__test:hover {
  transform: translateY(-2px);
  border-color: rgba(24, 144, 255, 0.3) !important;
}

.cs-health-insights__card-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 4px;
}

/* Brand color assignments for different metrics */
.cs-health-insights__card[data-type='health-score'] .cs-health-insights__icon-wrapper {
  background: rgba(101, 210, 216, 0.1);
  color: #65d2d8;
}

.cs-health-insights__card[data-type='test-completion'] .cs-health-insights__icon-wrapper {
  background: rgba(181, 215, 119, 0.1);
  color: #b5d777;
}

.cs-health-insights__card[data-type='immunity'] .cs-health-insights__icon-wrapper {
  background: rgba(253, 180, 98, 0.1);
  color: #fdb462;
}

.cs-health-insights__card[data-type='kidney'] .cs-health-insights__icon-wrapper {
  background: rgba(239, 141, 156, 0.1);
  color: #ef8d9c;
}

.cs-health-insights__card[data-type='respiratory'] .cs-health-insights__icon-wrapper {
  background: rgba(101, 210, 216, 0.1);
  color: #65d2d8;
}

.cs-health-insights__card[data-type='general'] .cs-health-insights__icon-wrapper {
  background: rgba(181, 215, 119, 0.1);
  color: #b5d777;
}

.cs-health-insights__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cs-health-insights__title {
  font-size: 14px;
  font-weight: 500;
}

.cs-health-insights__value {
  color: var(--metric-color) !important;
  font-size: 18px;
  font-weight: 600;
}

.cs-health-insights__update {
  font-size: 12px;
}

/* Glass effect for all cards */
.cs-health-insights__card::before,
.cs-health-insights__category::before,
.cs-health-insights__test::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  z-index: 0;
  filter: blur(2px);
}

/* Progress styles */
.cs-health-insights__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 8px;
}

/* Update progress circle colors */
.cs-health-insights__card[data-type='health-score'] .ant-progress-circle .ant-progress-circle-path {
  stroke: #65d2d8 !important;
}

/* Update progress bar colors */
.cs-health-insights__card[data-type='test-completion'] .ant-progress-line .ant-progress-bg {
  background: linear-gradient(90deg, #65d2d8, #b5d777) !important;
}

.cs-health-insights__category-container {
  margin-bottom: 32px;
}

.cs-health-insights__category-title {
  margin-bottom: 16px !important;
  font-size: 20px !important;
}

.cs-health-insights__tests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
}

.cs-health-insights__test {
  background: rgba(255, 255, 255, 0.03) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  transition: all 0.3s ease;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  overflow: hidden;
  min-height: 160px;
}

.cs-health-insights__test:hover {
  transform: translateY(-2px);
  border-color: rgba(24, 144, 255, 0.3) !important;
}

.test-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.cs-health-insights__test strong {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* Progress bar text */

/* Test description text */
.cs-health-insights__test .test-description {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.5;
}

/* Status text */
.cs-health-insights__test .test-status {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  text-transform: capitalize;
}

/* Status colors */
.test-status-normal {
  color: #52c41a !important;
  display: flex;
  align-items: center;
  gap: 6px;
}
.test-status-normal::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #52c41a;
}

/* Progress bar styling */
.cs-health-insights__test .ant-progress {
  margin: 4px 0 8px;
}

.cs-health-insights__test .ant-progress-bg {
  background: #65d2d8 !important;
  height: 6px !important;
}

/* Schedule test button */
.cs-health-insights__test .ant-btn.ant-btn-primary {
  border: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  height: 40px !important;
  width: 100% !important;
  margin-top: auto !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: linear-gradient(145deg, #65d2d8 0%, #b5d777 33%, #fdb462 66%, #ef8d9c 100%) !important;
  position: relative !important;
  overflow: hidden !important;
}

/* Metallic overlay */
.cs-health-insights__test .ant-btn.ant-btn-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

/* Neon glow effect */
.cs-health-insights__test .ant-btn.ant-btn-primary::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(145deg, #65d2d8 0%, #b5d777 33%, #fdb462 66%, #ef8d9c 100%);
  z-index: -1;
  filter: blur(8px);
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.cs-health-insights__test .ant-btn.ant-btn-primary:hover {
  transform: translateY(-1px);
  background: linear-gradient(145deg, #ef8d9c 0%, #fdb462 33%, #b5d777 66%, #65d2d8 100%) !important;
}

.cs-health-insights__test .ant-btn.ant-btn-primary:hover::after {
  opacity: 0.7;
  filter: blur(12px);
}

/* Active state */
.cs-health-insights__test .ant-btn.ant-btn-primary:active {
  transform: translateY(1px);
}

/* Important tag styling */
.cs-health-insights__test .ant-tag-red {
  background: rgba(245, 34, 45, 0.1) !important;
  border-color: rgba(245, 34, 45, 0.2) !important;
  color: #ff4d4f !important;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
}

/* Status colors */
.test-status-borderline {
  color: #faad14 !important;
}
.test-status-high,
.test-status-low {
  color: #f5222d !important;
}

/* Progress bar colors */
.cs-health-insights__test .ant-progress-status-exception .ant-progress-bg {
  background: #f5222d !important;
}

/* Ensure content is above the glass effect */
.cs-health-insights__card-content,
.cs-health-insights__category > *,
.cs-health-insights__test > * {
  position: relative;
  z-index: 1;
}

/* Adjust inner spacing of score details */
.cs-health-insights__score-details {
  margin-top: -8px;
}
