/* Main Container */
.mob-surgeries-container {
  min-height: 100vh;

  padding-bottom: 140px; /* Space for bottom actions */
}

/* Header Section */
.section-header {
  padding: 20px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.section-header h2 {
  font-size: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
}

.section-header .section-icon {
  color: #10b2e1;
  font-size: 20px;
}

.section-header p {
  margin: 4px 0 0 32px;
  font-size: 14px;
}

/* Add Surgery Button */
.new-surgery-btn {
  width: 100%;
  height: 44px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  border-radius: 8px;
  color: #10b2e1;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.new-surgery-btn:hover {
  background: rgba(16, 178, 225, 0.15);
}

/* Surgery List */
.surgery-list {
  padding: 16px;
}

.surgery-card {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  transition: all 0.2s ease;
}

.surgery-card:active {
  transform: scale(0.99);
}

.surgery-description {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 1.4;
}

.surgery-meta {
  display: flex;
  align-items: center;
  gap: 12px;
}

.year-badge {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  min-width: 70px;
  text-align: center;
}

.year-pending {
  font-size: 14px;
}

.surgery-actions {
  margin-left: auto;
  display: flex;
  gap: 8px;
}

.action-btn {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.delete-btn {
  background: rgba(239, 68, 68, 0.1);
  border: none;
  color: #ef4444;
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 40px 20px;
}

.empty-icon {
  font-size: 32px;
  margin-bottom: 12px;
  opacity: 0.5;
}

.empty-state p {
  font-size: 15px;
  margin: 0;
}

/* Bottom Actions */
.bottom-actions {
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  padding: 16px;
  display: flex;
  gap: 12px;
  z-index: 10;
}

.cancel-button {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  color: #ef4444;
}

.save-button {
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  color: #10b2e1;
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
