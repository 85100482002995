.cs-quick-actions-screen {
  min-height: 100vh;
  padding: 16px;
}

.cs-quick-actions-screen__header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Update header text styles */
.cs-quick-actions-screen__header .ant-typography {
  font-size: 18px !important;
  margin: 0 !important;
}

/* Style the back button */
.cs-quick-actions-screen__header .ant-btn-link {
  color: #65d2d8 !important;
  padding: 0 !important;
  font-size: 16px !important;
}

.cs-quick-actions-screen__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px 0;
}

.cs-quick-actions-screen__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: transparent !important;
  border: none !important;
  padding: 8px !important;
  height: auto !important;
}

.cs-quick-actions-screen__icon {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 4px;
}

.cs-quick-actions-screen__label {
  font-size: 11px !important;
  text-align: center;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

/* Add some styles for the Quick Actions button in Overview */
.cs-overview__quick-actions-btn {
  width: 100%;
  height: 48px !important;
  border-radius: 12px !important;
  background: linear-gradient(135deg, #65d2d8 0%, #10b2e1 100%) !important;
  border: none !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px;
}
