.mobile-nav-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.nav-item svg,
.nav-item span {
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.2s ease;
}

.nav-item svg {
  font-size: 20px;
}

.nav-item span {
  font-size: 12px;
}

.nav-item.active svg,
.nav-item.active span {
  color: #10b2e1;
}

/* META icon specific styles */
.nav-item.meta-item {
  position: relative;
  overflow: visible;
  border-radius: 50%;
  animation: none;
}

.nav-item.meta-item.active {
  transform: scale(1.25);
  border: 2px solid #10b2e1;

  z-index: 3;
  position: relative;
  box-shadow: inset 0 0 10px #10b2e1, inset 0 0 20px rgba(233, 30, 99, 0.5), 0 0 10px #10b2e1, 0 0 20px #10b2e1,
    0 0 30px rgba(233, 30, 99, 0.5);
  animation: neonBorderPulse 1.5s ease-in-out infinite, spinIcon 3s ease-out 1 forwards;
}

@keyframes neonBorderPulse {
  0%,
  100% {
    border-color: #10b2e1;
    box-shadow: inset 0 0 10px #10b2e1, inset 0 0 20px rgba(233, 30, 99, 0.5), 0 0 10px #10b2e1, 0 0 20px #10b2e1,
      0 0 30px rgba(233, 30, 99, 0.5);
  }
  50% {
    border-color: #e91e63;
    box-shadow: inset 0 0 15px #e91e63, inset 0 0 25px rgba(16, 178, 225, 0.5), 0 0 15px #e91e63, 0 0 25px #e91e63,
      0 0 35px rgba(16, 178, 225, 0.5);
  }
}

@keyframes spinIcon {
  0% {
    transform: scale(1.25) rotate(0deg);
  }
  100% {
    transform: scale(1.25) rotate(1080deg);
  }
}

.nav-item.meta-item.active::after {
  display: none;
}

.nav-item.meta-item .nav-icon {
  width: 32px;
  height: 32px;
  transition: all 0.2s ease;
}

.nav-item.meta-item.active .nav-icon {
  width: 40px;
  height: 40px;
}

/* Add padding to main content */
.mobile-dashboard {
  padding-bottom: 60px;
}

/* Ensure the glow effect is visible */
.nav-item.meta-item {
  position: relative;
  overflow: visible;
}

.nav-item.meta-item .nav-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.nav-item.meta-item .nav-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes neonBorderPulse {
  0%,
  100% {
    border-color: #10b2e1;
    box-shadow: inset 0 0 10px #10b2e1, inset 0 0 20px rgba(233, 30, 99, 0.5), 0 0 10px #10b2e1, 0 0 20px #10b2e1,
      0 0 30px rgba(233, 30, 99, 0.5);
  }
  50% {
    border-color: #e91e63;
    box-shadow: inset 0 0 15px #e91e63, inset 0 0 25px rgba(16, 178, 225, 0.5), 0 0 15px #e91e63, 0 0 25px #e91e63,
      0 0 35px rgba(16, 178, 225, 0.5);
  }
}
