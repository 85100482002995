.meal-card-wrapper {
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
}

.meal-suggestion-card.ant-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease;
  padding: 8px;
  width: 100%;
}

.meal-suggestion-card .meal-header {
  cursor: pointer;
}

.meal-suggestion-card .meal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 4px;
}

.meal-suggestion-card .title-group {
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
}

.meal-suggestion-card .meal-icon {
  color: #10b2e1;
  font-size: 16px;
  width: 20px;
}

.meal-suggestion-card .meal-title h3 {
  margin: 0;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
}

.meal-suggestion-card .calories-tag {
  background: rgba(233, 30, 99, 0.1);
  border: none;
  color: #E91E63;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 11px;
  margin: 0;
  flex-shrink: 0;
}

.meal-suggestion-card .time-range {
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  padding-left: 26px;
}

.meal-suggestion-card .suggestions-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
}

.meal-suggestion-card .suggestion-item .ant-tag {
  margin: 0;
  padding: 6px 10px;
  font-size: 13px;
  border: none;
  background: rgba(16, 178, 225, 0.1);
  color: #fff;
  width: 100%;
}

.meal-suggestion-card .meal-actions {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.meal-suggestion-card .complete-button {
  height: 44px;
  padding: 0 24px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid #10b2e1;
  color: #10b2e1;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meal-suggestion-card .complete-button:hover {
  background: rgba(16, 178, 225, 0.2);
}

.meal-suggestion-card .complete-button.completed {
  background: rgba(46, 204, 113, 0.1);
  border-color: #2ecc71;
  color: #2ecc71;
}

.meal-suggestion-card .complete-button.completed:hover {
  background: rgba(46, 204, 113, 0.2);
}

.meal-suggestion-card .transparent-action-button {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid #10b2e1;
  color: #10b2e1;
  padding: 0;
}

.meal-suggestion-card .transparent-action-button:hover {
  background: rgba(16, 178, 225, 0.2);
}

.meal-suggestion-card .expand-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  transition: transform 0.2s ease;
}

.meal-suggestion-card .expand-icon.expanded {
  transform: rotate(180deg);
}

/* Expanded content styles */
.meal-suggestion-card .meal-content {
  overflow: hidden;
  padding-top: 16px;
  margin-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
} 