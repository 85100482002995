/* Card Styles */
.overviewCard {
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%);
  overflow: hidden;
}

/* Header Section */
.header {
  padding: 0 0 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 24px;
}

.greeting {
  display: flex;
  align-items: center;
  gap: 12px;
}

.greetingText {
  display: flex;
  flex-direction: column;
}

.hello {
  font-size: 24px;
  font-weight: 500;
  color: #241422;
  margin: 0;
}

.subText {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin: 0;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

.profileSection {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 4px;
  border-radius: 24px;
  transition: all 0.3s ease;
}

.profileSection:hover {
  background: rgba(0, 0, 0, 0.02);
}

.avatar {
  background: #1890ff;
  color: #ffffff;
}

/* Summary Section */
.summarySection {
  padding: 24px 32px;
  border-bottom: 1px solid #f0f0f0;
  background: linear-gradient(145deg, #f0f2f8 0%, #f5f7ff 100%);
  border-radius: 16px;
  margin: 0 16px;
}

.summaryHeader {
  margin-bottom: 24px;
}

.cardTitle {
  font-size: 20px;
  color: #262626;
  margin: 0;
  font-weight: 600;
}

.statsGrid {
  margin: 0 -8px;
}

.statBox {
  background: #ffffff;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.statBox:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.statIcon {
  font-size: 24px;
  color: #1890ff;
  padding: 12px;
  background: #e6f7ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.statNumber {
  font-size: 28px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 4px;
  line-height: 1;
}

.statLabel {
  font-size: 14px;
  color: #8c8c8c;
  margin: 0;
  white-space: nowrap;
}

.trendIndicator {
  font-size: 13px;
  color: #52c41a;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
}

.trendDown {
  color: #ff4d4f;
}

/* Appointments List Section */
.upcomingSection {
  padding: 24px 32px 32px;
  background: #fff;
}

.sectionTitle {
  font-size: 18px;
  color: #262626;
  margin-bottom: 24px;
  padding-bottom: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.appointmentsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.appointmentOverviewItem {
  padding: 12px 24px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: all 0.2s ease;
}

.appointmentOverviewItem:hover {
  border-color: rgba(24, 144, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.appointmentOverviewActions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.statusTag {
  margin: 0;
  font-size: 13px;
  padding: 4px 12px;
  border-radius: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: none;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
}

.appointmentOverviewContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 24px;
  padding: 0 16px;
}

.appointmentOverviewPatient {
  display: flex;
  gap: 16px;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.appointmentOverviewAvatar {
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  font-size: 16px !important;
  flex-shrink: 0;
  border: 2px solid #fff;
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.15);
}

.appointmentOverviewInfo {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.appointmentOverviewNameRow {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 4px;
}

.appointmentOverviewName {
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appointmentOverviewAge {
  color: #8c8c8c;
  font-size: 14px;
  flex-shrink: 0;
}

.appointmentOverviewDetails {
  display: flex;
  gap: 16px;
  align-items: center;
}

.appointmentOverviewTime {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #262626;
  min-width: 120px;
}

.appointmentOverviewTime :global(.anticon),
.appointmentOverviewReason :global(.anticon) {
  color: #1890ff;
  font-size: 14px;
  flex-shrink: 0;
  background: #e6f7ff;
  padding: 6px;
  border-radius: 6px;
}

.appointmentOverviewTimeValue {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
}

.appointmentOverviewDuration {
  font-size: 12px;
  color: #8c8c8c;
}

.appointmentOverviewReason {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #595959;
  flex: 1;
  min-width: 0;
}

.appointmentOverviewReason span {
  font-size: 13px;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.aiButton {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aiButton:hover {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.85);
}

.startConsultButton {
  flex-shrink: 0;
  min-width: 120px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: auto;
}

.startConsultButton:global(.ant-btn-primary) {
  background: #52c41a !important;
  border-color: #52c41a !important;
}

.startConsultButton:global(.ant-btn-primary:hover) {
  background: #49b108 !important;
  border-color: #49b108 !important;
}

.startConsultButton:global(.ant-btn-primary:active) {
  background: #3d9404 !important;
  border-color: #3d9404 !important;
}

.appointmentItem {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s;
  margin: 8px 0;
  background: white;
  flex-wrap: wrap;
  gap: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.appointmentItem:hover {
  background-color: #fafafa;
}

.timeInfo {
  min-width: 80px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.clockIcon {
  color: #1890ff;
  font-size: 16px;
}

.timeText {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
}

.patientInfo {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 16px;
}

.patientAvatar {
  background: #1890ff;
  color: white;
}

.patientDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.patientName {
  font-size: 15px;
  font-weight: 500;
  color: #262626;
}

.appointmentType {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #8c8c8c;
  font-size: 13px;
}

.serviceText {
  color: #595959;
}

.virtualIcon {
  color: #1890ff;
}

.statusTag {
  min-width: 80px;
  text-align: center;
  border-radius: 4px;
  text-transform: capitalize;
}

.upcomingSection {
  margin-top: 24px;
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 8px;
}

.sectionTitle span {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
}

.appointmentsList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.appointmentActions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}

.startConsultButton {
  flex-shrink: 0;
  min-width: 120px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.startConsultButton:global(.ant-btn-primary) {
  background: #52c41a !important;
  border-color: #52c41a !important;
}

.startConsultButton:global(.ant-btn-primary:hover) {
  background: #49b108 !important;
  border-color: #49b108 !important;
}

.startConsultButton:global(.ant-btn-primary:active) {
  background: #3d9404 !important;
  border-color: #3d9404 !important;
}
