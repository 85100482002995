.section-header {
  padding: 20px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(16, 24, 40, 0.6);
}

.section-header h2 {
  color: white;
  font-size: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
}

.section-header p {
  margin: 4px 0 0 32px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.section-header .section-icon {
  color: #10b2e1;
  font-size: 20px;
}

.mob-meds-container {
  padding: 0 0 120px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: calc(100vh - 130px);
  position: relative;
}

/* Warning Message */
.warning-message {
  margin: 16px;
  background: rgba(245, 158, 11, 0.1);
  border: 1px solid rgba(245, 158, 11, 0.2);
  padding: 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgb(245, 158, 11);
  font-size: 14px;
}

.warning-icon {
  font-size: 16px;
  flex-shrink: 0;
}

/* Search Section */
.search-section {
  position: relative;
  z-index: 10;
  background: transparent;
  padding: 8px 0;
  margin-bottom: 16px;
}

.med-search {
  width: 100%;
}

/* Medications List */
.medications-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.medication-card {
  background: linear-gradient(145deg, rgba(245, 158, 11, 0.15) 0%, rgba(245, 158, 11, 0.05) 100%) !important;
  border: 1.5px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 16px !important;
  overflow: hidden;
}

.med-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.med-icon {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: rgba(245, 158, 11, 0.15);
  color: rgb(245, 158, 11);
}

.med-info {
  flex: 1;
}

.med-info h3 {
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px;
}

.med-info p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin: 0;
}

.med-dates {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.date-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.date-field label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

.end-date-btn {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

.delete-med-btn {
  width: 100%;
  background: transparent;
  border: none;
  color: #ef4444;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.delete-med-btn:hover {
  background: rgba(239, 68, 68, 0.1) !important;
  color: #ef4444 !important;
}

.save-changes-btn {
  position: sticky;
  bottom: 16px;
  width: 100%;
  height: 48px;
  background: rgb(245, 158, 11);
  border: none;
  border-radius: 12px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
}

.save-changes-btn:hover {
  background: rgb(234, 147, 0) !important;
  color: white !important;
}

/* Action Buttons */
.action-buttons {
  display: none;
}

.bottom-actions {
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  padding: 16px;
  backdrop-filter: blur(8px);
  display: flex;
  gap: 8px;
  z-index: 1000;
}

.cancel-button,
.save-button {
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  font-weight: 500;
}

.cancel-button {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid rgba(239, 68, 68, 0.2);
  color: #ef4444;
}

.cancel-button:hover {
  background: rgba(239, 68, 68, 0.15) !important;
  border-color: rgba(239, 68, 68, 0.3) !important;
  color: #ef4444 !important;
}

.save-button {
  background: rgba(16, 185, 129, 0.1);
  border: 1px solid rgba(16, 185, 129, 0.2);
  color: #10b981;
}

.save-button:hover {
  background: rgba(16, 185, 129, 0.15) !important;
  border-color: rgba(16, 185, 129, 0.3) !important;
  color: #10b981 !important;
}

/* Add section heading styles */
.section-heading {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.section-heading .section-icon {
  font-size: 24px;
  color: #10b2e1;
}

.section-heading h2 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

/* Modern Search Bar Styles */
.med-search {
  width: 100%;
}
