.modalContent {
  padding: 24px;
}

.formSection {
  margin-bottom: 24px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.divider {
  margin: 24px 0;
}

.textArea {
  min-height: 120px;
}

.label {
  font-weight: 500;
  margin-bottom: 8px;
}

.datePicker {
  width: 100%;
} 