.cs-category {
  margin-bottom: 24px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.cs-category__header {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Remove the expanded border styles */
.cs-category.expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.cs-category__title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.cs-category__name {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.cs-category__count {
  font-size: 14px;
}

.cs-category__icon {
  font-size: 20px;
  width: 24px;
}

.cs-category__actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.cs-category__count-tag {
  background: rgba(101, 210, 216, 0.1);
  color: #65d2d8;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  min-width: 24px;
  text-align: center;
  border: 1px solid rgba(101, 210, 216, 0.2);
}

.cs-category__chevron {
  transition: transform 0.3s ease;
}

.cs-category__content {
  padding: 0 16px 16px;
  animation: cs-category-slide-down 0.2s ease-out;
}

.cs-category__description {
  margin-bottom: 16px;
  padding: 0 8px;
}

.cs-category__description-text {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

.cs-category__tests {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
}

@keyframes cs-category-slide-down {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
