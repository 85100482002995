.filterBar {
  margin-bottom: 16px;
  padding: 8px;
  background: #fafafa;
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filterSelect {
  min-width: 120px;
  flex: 1;
}

.filterSelect :global(.ant-select-selection-item) {
  display: flex;
  align-items: center;
  gap: 4px;
}

.filterSelect :global(.ant-select-item-option-content) {
  display: flex;
  align-items: center;
  gap: 4px;
} 