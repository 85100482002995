.linktop-pulse {
  animation: pulse-linktop 3s infinite;
  border-radius: 50%;
}

@keyframes pulse-linktop {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0px rgba(0, 123, 255, 1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

/* Add a new animation for connection status */
.connection-status-blink {
  animation: blink-animation 1.5s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

/* Add a pulsing animation for device activity */
.device-active {
  animation: device-pulse 2s infinite;
}

@keyframes device-pulse {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.03);
    filter: brightness(1.1);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

/* Add a shake animation for error states */
.device-error {
  animation: device-shake 0.5s;
}

@keyframes device-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  20%,
  60% {
    transform: translateX(-5px);
  }
  40%,
  80% {
    transform: translateX(5px);
  }
}
