.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  isolation: isolate;
  position: fixed;
  inset: 0;
}

.modal {
  background: transparent;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  padding: 20px;
  position: relative;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin: 0 0 12px 0;
}

.subtitle {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 8px;
  line-height: 1.4;
}

.closeButton {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
  padding: 8px;
  cursor: pointer;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 140px;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.eta {
  margin-top: 24px;
  padding: 12px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 12px;
  text-align: center;
}

.statusMessage {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-style: italic;
  margin: 0;
}

.modal,
.header {
  border-radius: 0;
}

.content::after {
  content: '';
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  pointer-events: none;
  z-index: 5;
}
