.mobile-new-image {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-bottom: 122px;
}

.preview-section {
  border-radius: 16px;
  padding: 12px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  margin-bottom: 0;
}

.meal-preview {
  width: 100%;
  border-radius: 8px;
  display: block;
  margin-bottom: 0;
}

.save-options {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 16px !important;
  margin: 0 !important;
  padding: 8px !important;
}

.action-buttons {
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  display: flex;
  gap: 12px;
  padding: 12px 16px;

  border-top: 1px solid rgba(16, 178, 225, 0.15);
  z-index: 100;
  margin: 8px 8px 0 8px;
}

.cancel-button,
.confirm-button {
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  margin: 0 4px;
}

.cancel-button {
  background: rgba(233, 30, 99, 0.1);
  border: 1px solid #e91e63;
  color: #e91e63;
}

.confirm-button {
  background: #10b2e1;
}

.error-message {
  background: rgba(233, 30, 99, 0.1) !important;
  border: 1px solid #e91e63 !important;
  color: #e91e63;
  margin-top: 16px;
  border-radius: 8px;
  text-align: center;
}

.capture-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-upload-modal {
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mobile-rings-card {
  margin: 0 !important;
}

.healthy-alt-button {
  margin-bottom: 8px;
  width: 100%;
}

.success-message {
  background: rgba(82, 196, 26, 0.1) !important;
  border: 1px solid #52c41a !important;
  margin-bottom: 8px;
  padding: 8px !important;
}

.disclaimer-note {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 16px !important;
  margin-top: 2px !important;
  margin-bottom: 8px !important;
  padding: 8px !important;
}

.note-title {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  margin-bottom: 4px;
}

.note-text {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.4;
  margin: 0;
}

.section-header {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 16px !important;
  margin-bottom: 2px !important;
  padding: 8px !important;
}

.section-header h2 {
  color: white;
  font-size: 18px;
  margin: 0;
  text-align: center;
}

.meal-description {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 16px !important;
  margin: 2px 0 !important;
  padding: 8px !important;
}

.meal-description p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

/* Adjust spacing between components */
.preview-section,
.healthy-alt-button,
.success-message,
.save-options,
.disclaimer-note {
  margin-bottom: 2px !important;
}
