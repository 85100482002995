/* Previous Plan Modal Styling */
.previous-plan-modal.ant-modal {
  width: 90% !important;
  max-width: 360px !important;
}

.previous-plan-modal .ant-modal-content {
  background: #141824 !important;
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 16px !important;
  padding: 0 !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2) !important;
  overflow: hidden !important;
}

.previous-plan-modal .ant-modal-header {
  margin: 0 !important;
  padding: 16px 20px !important;
  background: #141824 !important;
  border-bottom: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 16px 16px 0 0 !important;
}

.previous-plan-modal .ant-modal-title {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

.previous-plan-modal .ant-modal-body {
  padding: 20px !important;
  background: #141824 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
}

.previous-plan-modal .ant-modal-footer {
  margin: 0 !important;
  padding: 16px 20px !important;
  background: #141824 !important;
  border-top: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 0 0 16px 16px !important;
}

.previous-plan-modal .ant-modal-footer .ant-space {
  width: 100% !important;
  justify-content: flex-end !important;
  gap: 12px !important;
}

/* Modal Button Styling */
.previous-plan-modal .wizard-modal-button {
  height: 40px !important;
  padding: 0 24px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 130px !important;
}

.previous-plan-modal .wizard-modal-button.secondary {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

.previous-plan-modal .wizard-modal-button.secondary:hover {
  background: rgba(16, 178, 225, 0.15) !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 12px rgba(16, 178, 225, 0.2) !important;
}

.previous-plan-modal .wizard-modal-button.primary {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

.previous-plan-modal .wizard-modal-button.primary:hover {
  background: rgba(16, 178, 225, 0.15) !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 12px rgba(16, 178, 225, 0.2) !important;
}

.previous-plan-modal .wizard-modal-button:active {
  transform: translateY(0) !important;
}

/* Modal Close Button */
.previous-plan-modal .ant-modal-close {
  top: 16px !important;
  right: 16px !important;
  width: 32px !important;
  height: 32px !important;
  opacity: 0.7 !important;
}

.previous-plan-modal .ant-modal-close-x {
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
  color: rgba(255, 255, 255, 0.6) !important;
}

.previous-plan-modal .ant-modal-close:hover {
  opacity: 1 !important;
}

/* Modal Mask */
.previous-plan-modal .ant-modal-mask,
.previous-plan-modal ~ .ant-modal-mask {
  background-color: rgba(20, 24, 36, 0.95) !important;
  backdrop-filter: blur(4px) !important;
}

/* Modal Wrapper */
.previous-plan-modal .ant-modal-wrap {
  background: transparent !important;
}
