.mobile-virtual-booking {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Ensure content doesn't go under the navbar */
.mobile-virtual-booking .mobile-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

/* Ensure proper spacing for the flow */
.mobile-virtual-booking .steps-container {
  padding-bottom: 80px; /* Account for navbar */
}

/* Ensure the next button doesn't conflict with navbar */
.mobile-virtual-booking .next-button {
  bottom: 96px; /* Position above navbar */
}
