.ql-editor {
  min-height: 600px; /* Set your desired minimum height */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    left: 0;
  }
  100% {
    transform: rotate(360deg);
    color: red;
  }
}

.rotating-square {
  position: relative;
  animation: rotate 2s linear infinite;
}

/* Referral Letter Styles */

.referral-card {
  width: 100%;
  min-height: 80vh;
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  background: #fff;
  background-image: radial-gradient(#f0f0f0 1px, transparent 1px), radial-gradient(#f0f0f0 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  position: relative;
  overflow: hidden;
}

.referral-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(to right, #722ed1, #1890ff);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.referral-option-card {
  text-align: center;
  min-height: 250px;
  height: auto;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid #e8e8e8;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background: white;
  margin-top: 8px;
}

.referral-option-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.referral-option-card.selected {
  border-color: #1890ff;
  box-shadow: 0 0 12px rgba(24, 144, 255, 0.3);
}

.referral-option-icon {
  font-size: 36px;
  margin-bottom: 12px;
  transition: transform 0.3s ease;
}

.referral-option-card:hover .referral-option-icon {
  transform: scale(1.1);
}

.referral-option-card .ant-typography {
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.referral-template-card {
  border-radius: 12px;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  overflow: hidden;
}

.referral-template-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.referral-editor {
  min-height: 300px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.referral-preview {
  padding: 20px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  min-height: 300px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
}

.referral-data-tag {
  margin: 4px;
  display: inline-flex;
  align-items: center;
}

.referral-recent-template {
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  overflow: hidden;
}

.referral-recent-template:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-color: #1890ff;
}

/* AI Configuration Screen Styles */
.ai-config-container {
  background: linear-gradient(to bottom right, #ffffff, #f9f9ff);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.ai-config-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(to right, #722ed1, #1890ff);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ai-config-title {
  color: #303030;
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
}

.ai-config-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 40px;
  height: 3px;
  background: #722ed1;
  border-radius: 3px;
}

.ai-config-subtitle {
  color: #666;
  margin-bottom: 32px;
  font-size: 16px;
}

.ai-config-form-item {
  margin-bottom: 24px;
}

.ai-config-form-item label {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.ai-config-input {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 10px 12px;
  transition: all 0.3s;
}

.ai-config-input:hover {
  border-color: #40a9ff;
}

.ai-config-input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ai-config-select {
  width: 100%;
  border-radius: 8px;
}

.ai-config-textarea {
  border-radius: 8px;
  min-height: 120px;
  resize: vertical;
}

.ai-config-divider {
  margin: 32px 0 24px;
  position: relative;
}

.ai-config-divider::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  z-index: 1;
}

.ai-config-divider-text {
  position: relative;
  z-index: 2;
  background: #f9f9ff;
  padding: 0 16px;
  color: #722ed1;
  font-weight: 500;
}

.ai-config-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.ai-config-back-button {
  border-radius: 8px;
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.ai-config-generate-button {
  border-radius: 8px;
  padding: 0 24px;
  height: 40px;
  background: linear-gradient(to right, #722ed1, #1890ff);
  border: none;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  box-shadow: 0 2px 6px rgba(114, 46, 209, 0.4);
}

.ai-config-generate-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(114, 46, 209, 0.5);
}

.ai-config-generate-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(114, 46, 209, 0.4);
}

.ai-config-generate-button:disabled {
  background: #d9d9d9;
  box-shadow: none;
}

.recent-templates-section {
  margin-bottom: 32px;
}

.recent-template-card {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s;
  border: 1px solid #f0f0f0;
  background: white;
}

.recent-template-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  border-color: #722ed1;
}

.recent-template-header {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  background: #fafafa;
}

.recent-template-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recent-template-content {
  padding: 12px 16px;
}

.recent-template-detail {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
  display: flex;
}

.recent-template-label {
  font-weight: 500;
  margin-right: 4px;
}

.data-point-tag {
  margin: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s;
}

.data-point-tag:hover {
  transform: translateY(-2px);
}

.data-templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.data-template-card {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s;
  border: 1px solid #f0f0f0;
  background: white;
  height: 100%;
}

.data-template-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  border-color: #722ed1;
}

.data-template-header {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  background: #fafafa;
}

.data-template-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.data-template-content {
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
}

.data-points-counter {
  margin-top: 8px;
  font-size: 13px;
  color: #666;
  display: flex;
  align-items: center;
}

.data-points-counter-icon {
  margin-right: 6px;
  color: #1890ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .referral-card {
    padding: 16px;
  }

  .referral-option-card {
    height: 180px;
    padding: 24px 16px;
  }

  .ai-config-container {
    padding: 16px;
  }

  .data-templates-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

/* Quill editor overrides */
.ql-editor {
  min-height: 250px;
  font-size: 14px;
}

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.referral-selection-screen {
  padding: 40px 0 24px;
  max-width: 900px;
  margin: 0 auto;
}

.referral-config-screen,
.referral-editor-screen {
  padding: 24px 0;
  max-width: 900px;
  margin: 0 auto;
}
