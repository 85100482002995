.mob-family-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);

  position: relative;
  padding: 16px 16px 0 16px;
  overflow-y: auto;
  padding-bottom: 140px;
}

/* Header Styling */
.section-header {
  text-align: center;
  margin-bottom: 16px;
  padding: 12px;
  animation: fadeIn 0.3s ease-out;
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 16px;
}

.section-header h2 {
  font-size: 20px;
  font-weight: 600;

  margin: 0 0 8px;
  line-height: 1.2;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.section-header h2 .section-icon {
  font-size: 20px;
  color: #10b2e1;
}

.section-header p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  margin: 0;
  line-height: 1.4;
}

/* Conditions Grid */
.conditions-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 80px;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

.condition-card {
  width: 100%;
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 16px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  min-height: 76px;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.condition-card:hover {
  background: rgba(16, 178, 225, 0.05);
  border-color: rgba(16, 178, 225, 0.3);
  transform: translateY(-1px);
}

.condition-card:active {
  transform: scale(0.98);
}

.condition-card::before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border: 2px solid rgba(16, 178, 225, 0.4);
  border-radius: 6px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.condition-card::after {
  content: '✓';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 16px;
  opacity: 0;
  transition: all 0.2s ease;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.condition-card[aria-checked='true'] {
  background: rgba(16, 178, 225, 0.15);
  border-color: #10b2e1;
}

.condition-card[aria-checked='true']::before {
  background: #10b2e1;
  border-color: #10b2e1;
}

.condition-card[aria-checked='true']::after {
  opacity: 1;
}

.condition-icon {
  width: 40px;
  height: 40px;
  background: rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #10b2e1;
  font-size: 18px;
  flex-shrink: 0;
}

.condition-name {
  font-size: 16px;
  font-weight: 500;
  margin-right: 40px;
  flex: 1;
}

/* Bottom Actions */
.bottom-actions {
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  padding: 0 16px 12px;
  background: transparent;
  display: flex;
  gap: 8px;
  z-index: 1000;
  pointer-events: auto;
}

.cancel-button {
  background: rgba(239, 68, 68, 0.1);
  border: 1px solid #e91e63;
  color: #e91e63;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

.cancel-button:hover {
  background: rgba(233, 30, 99, 0.15) !important;
}

.save-button {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}

.save-button:hover {
  background: rgba(16, 178, 225, 0.15) !important;
}
