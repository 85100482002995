/* Day header */
.workout-plan-tab .day-header {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
}

.workout-plan-tab .day-name {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 500;
  flex: 1;
}

.workout-plan-tab .collapse-icon {
  color: #10b2e1;
  transition: transform 0.3s ease;
}

.workout-plan-tab .collapse-icon.active {
  transform: rotate(180deg);
}

/* Wrapper for each workout card */
.workout-plan-tab .workout-wrapper {
  padding-top: 20px;
  margin-bottom: 56px;
  position: relative;
}

.workout-plan-tab .workout-wrapper::before {
  content: 'WORKOUT';
  font-size: 11px;
  font-weight: 500;
  color: rgba(16, 178, 225, 0.7);
  letter-spacing: 0.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.workout-plan-tab .workout-wrapper:last-child {
  margin-bottom: 24px;
}

/* Workout Card Styling */
.workout-plan-tab .ant-card {
  background: #141824 !important;
  border: 1.5px solid rgba(16, 178, 225, 0.25) !important;
  border-radius: 12px !important;
}

/* Workout Header */
.workout-plan-tab .workout-header {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(16, 178, 225, 0.15);
  position: relative;
  z-index: 1;
}

.workout-plan-tab .workout-title {
  margin-bottom: 20px;
  position: relative;
}

.workout-plan-tab .workout-type {
  display: flex;
  align-items: center;
  gap: 12px;
}

.workout-plan-tab .workout-icon {
  color: #10b2e1;
  font-size: 18px;
  filter: drop-shadow(0 0 6px rgba(16, 178, 225, 0.3));
}

.workout-plan-tab .workout-name {
  color: rgba(255, 255, 255, 0.9);
  font-size: 18px;
  font-weight: 500;
}

.workout-plan-tab .workout-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workout-plan-tab .meta-left {
  display: flex;
  gap: 8px;
}

.workout-plan-tab .verification-tag {
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.25) !important;
  color: #10b2e1 !important;
}

.workout-plan-tab .difficulty-tag {
  font-size: 12px;
  padding: 2px 8px;
  height: auto;
  line-height: 1.5;
  border-radius: 4px;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.25) !important;
}

.workout-plan-tab .points-badge {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #ffd700;
  font-weight: 500;
  filter: drop-shadow(0 0 6px rgba(255, 215, 0, 0.3));
}

/* Stats Section */
.workout-plan-tab .workout-stats {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  padding: 12px 16px;
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 8px;
  position: relative;
  z-index: 1;
}

.workout-plan-tab .stat {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.workout-plan-tab .stat svg {
  color: #10b2e1;
  font-size: 14px;
  filter: drop-shadow(0 0 6px rgba(16, 178, 225, 0.3));
}

/* Exercises container */
.workout-plan-tab .workout-exercises {
  margin: 1px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  z-index: 1;
}

/* Collapse Panel Customization */
.workout-plan-tab .ant-collapse {
  background: transparent !important;
  border: none !important;
}

.workout-plan-tab .ant-collapse-item {
  background: rgba(16, 178, 225, 0.05) !important;
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 12px !important;
  margin-bottom: 12px !important;
}

.workout-plan-tab .ant-collapse-header {
  padding: 16px !important;
  border-radius: 12px !important;
}

.workout-plan-tab .ant-collapse-content {
  background: transparent !important;
  border-top: 1px solid rgba(16, 178, 225, 0.15) !important;
}

.workout-plan-tab .ant-collapse-content-box {
  padding: 20px !important;
}

/* Progress Bar Customization */
.workout-plan-tab .ant-progress {
  width: 120px !important;
}

.workout-plan-tab .ant-progress-bg {
  background: linear-gradient(90deg, #10b2e1, #e91e63) !important;
}

/* Progress Bar Text and Arrow Customization */
.workout-plan-tab .ant-collapse-header-text {
  flex: 1 !important;
}

.workout-plan-tab .ant-collapse-expand-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}

.workout-plan-tab .ant-progress .ant-progress-text {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.workout-plan-tab .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: rgba(255, 255, 255, 0.9) !important;
  align-items: center !important;
}
