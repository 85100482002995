.test-description-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.test-description-card:hover {
  background: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.15);
}

.test-description-card .ant-typography {
  margin-bottom: 16px;
}

/* Title styling */
.test-description-card h4.ant-typography {
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

/* Description text styling */
.test-detail-description.ant-typography {
  color: rgba(255, 255, 255, 0.65);
  line-height: 1.6;
  font-size: 14px;
  display: block;
} 