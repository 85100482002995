.mobile-macro-rings {
  position: relative;

  padding: 0 16px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mobile-rings-card {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  padding: 8px !important;
  margin: 0 !important;
}

/* Info grid layout */
.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.info-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 8px;
}

.info-column strong {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 4px;
  font-size: 14px;
}

.info-column p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

/* Button container - position relative to content */
.mobile-rings-button-container {
  display: none;
}

/* Action button styling */
.mobile-rings-button-container .action-button {
  width: 100% !important;
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0 0 12px 0 !important;
  padding: 0 16px !important;
  border-radius: 8px !important;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid #10b2e1 !important;
  color: #10b2e1 !important;
}

/* Button hover state */
.mobile-rings-button-container .action-button:hover {
  background: rgba(16, 178, 225, 0.2) !important;
}

/* Last button (no margin bottom) */
.mobile-rings-button-container .action-button:last-child {
  margin-bottom: 0 !important;
}

/* Ensure content is scrollable */
@media screen and (max-width: 768px) {
  .mobile-macro-rings {
    height: calc(100vh - 65px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-rings-card {
    margin-bottom: 24px !important;
  }
}

/* iOS safe area support */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .mobile-macro-rings {
    padding-bottom: calc(160px + env(safe-area-inset-bottom)) !important;
  }
}

/* Add these styles for servings control */
.servings-control {
  margin-bottom: 8px;
  padding: 8px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 8px;
}

.servings-control span {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.servings-value {
  min-width: 24px;
  text-align: center;
  font-weight: 500;
  color: white !important;
}

/* Rings grid layout */
.rings-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  margin: 16px 0 8px 0;
}

.nutrient-ring {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  padding: 12px;
  background: rgba(16, 178, 225, 0.05);
  border-radius: 8px;
}

.ring-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.ring-content .value {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.ring-content .label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

/* Add new class for compact info grid */
.info-grid.compact {
  margin-top: 8px !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.mobile-macro-rings.no-bottom-padding {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.mobile-rings-card {
  margin-bottom: 0 !important;
}
