.appointments-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.upcoming-appointments {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
}

.glass-appointment-card {
  position: relative;
  z-index: 1;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid rgba(16, 178, 225, 0.15);
  overflow: hidden;
  width: 100%;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  will-change: transform;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

/* Glass effect - background glow */
.glass-appointment-card::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 14px;
  background: linear-gradient(
    145deg,
    rgba(16, 178, 225, 0.15) 0%,
    rgba(16, 178, 225, 0.05) 50%,
    rgba(16, 178, 225, 0.02) 100%
  );
  z-index: 0;
  filter: blur(2px);
}

/* Card shine effect */
.glass-appointment-card::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 13px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7), 0 0 15px rgba(16, 178, 225, 0.05), inset 0 2px 2px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

/* Ensure content stays above effects */
.glass-appointment-card > * {
  position: relative;
  z-index: 2;
}

.appointment-header {
  text-align: left;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.specialty {
  color: #10b2e1;
  font-size: 14px;
  margin-top: 4px;
}

.appointment-type {
  display: inline-block;
  background: rgba(16, 178, 225, 0.1);
  color: #10b2e1;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 13px;
}

.appointment-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.detail-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  background: rgba(16, 178, 225, 0.03);
  padding: 12px;
  border-radius: 8px;
  position: relative;
}

.detail-content {
  flex: 1;
}

.detail-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.detail-icon {
  color: #10b2e1;
  font-size: 16px;
  width: 20px;
  text-align: center;
}

.detail-label {
  font-size: 12px;
  text-transform: uppercase;
}

.detail-value {
  font-size: 14px;
  padding-left: 28px; /* To align with content after icon */
}

.travel-info .detail-value {
  color: #10b2e1;
}

.detail-item.clickable {
  cursor: pointer;
  transition: all 0.2s ease;
}

.detail-item.clickable:hover {
  background: rgba(16, 178, 225, 0.06);
}

.detail-item.clickable:active {
  transform: scale(0.98);
}

.detail-value.with-icon {
  display: flex;
  align-items: center;
  gap: 6px;
}

.detail-value .action-icon {
  font-size: 12px;
  opacity: 0.6;
  color: #10b2e1;
}

.detail-item.clickable:hover .action-icon {
  opacity: 1;
}

.custom-tabs-content {
  padding: 16px 0;

  min-height: 300px;
}

.detail-item.clickable .detail-value.with-icon {
  color: #10b2e1;
}

.detail-item.clickable:hover .detail-value.with-icon {
  text-decoration: underline;
}

.detail-item.with-action {
  padding-right: 48px;
}

.detail-action-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(16, 178, 225, 0.1);
  border: 1px solid rgba(16, 178, 225, 0.2);
  color: #10b2e1;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.detail-action-button:hover {
  background: rgba(16, 178, 225, 0.15);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(16, 178, 225, 0.2);
}

.detail-action-button:active {
  transform: translateY(0);
}

.detail-action-button svg {
  font-size: 14px;
}

/* Regular items without actions */
.detail-item:not(.with-action) {
  padding: 12px;
}

.detail-item:not(.with-action) .detail-left {
  justify-content: space-between;
  align-items: center;
}

.detail-item:not(.with-action) .detail-value {
  text-align: right;
}

.card-preview {
  cursor: pointer;
  position: relative;
  padding-bottom: 8px;
}

.preview-details {
  margin-top: 16px;
}

.preview-details .detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: none;
  padding: 0;
}

.preview-details .detail-icon {
  color: #10b2e1;
  font-size: 14px;
  flex-shrink: 0;
}

.preview-details .detail-value {
  font-size: 14px;
}

.expand-button {
  position: absolute;
  right: 0;
  bottom: 0;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.expand-button.expanded {
  transform: rotate(180deg);
}

.expanded-details {
  border-top: 1px solid rgba(16, 178, 225, 0.1);
  margin-top: 8px;
  padding-top: 16px;
  overflow: hidden;
}

.appointment-actions {
  display: flex;
  gap: 12px;
  padding: 16px 0 0;
  border-top: 1px solid rgba(16, 178, 225, 0.1);
  margin-top: 8px;
}

.appointment-actions .action-button {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.appointment-actions .action-button.cancel {
  background: rgba(255, 59, 48, 0.1);
  color: #ff3b30;
}

.appointment-actions .action-button.reschedule {
  background: rgba(16, 178, 225, 0.1);
  color: #10b2e1;
}

.appointment-actions .action-button:hover {
  transform: translateY(-1px);
}

.appointment-actions .action-button:active {
  transform: translateY(0);
}

.appointment-actions .action-button.cancel:hover {
  background: rgba(255, 59, 48, 0.15);
}

.appointment-actions .action-button.reschedule:hover {
  background: rgba(16, 178, 225, 0.15);
}

.upcoming-appointments :global(.adm-picker) {
  background: #141824 !important;
}

.upcoming-appointments :global(.adm-picker-header) {
  background: #141824 !important;
  border-bottom: 1px solid rgba(16, 178, 225, 0.1);
}

.upcoming-appointments :global(.adm-picker-header-button) {
  color: #10b2e1;
  font-size: 14px;
}

.upcoming-appointments :global(.adm-picker-view) {
  background: #141824 !important;
}

.upcoming-appointments :global(.adm-picker-view-column) {
  flex: 1;
}

.upcoming-appointments :global(.adm-picker-view-mask) {
  background: linear-gradient(to bottom, #141824 0%, rgba(20, 24, 36, 0.6) 50%, #141824 100%) !important;
}

.reschedule-picker :global(.adm-picker-view-mask) {
  background: linear-gradient(to bottom, #141824 0%, rgba(20, 24, 36, 0.6) 50%, #141824 100%);
}

.appointment-wrapper {
  margin-bottom: 16px;
  position: relative;
}

.expanded-content {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  will-change: transform;
}

.no-appointments-placeholder {
  text-align: center;
  background: rgba(20, 24, 36, 0.6);
  border-radius: 16px;
  margin: 16px;
  border: 1px solid rgba(16, 178, 225, 0.1);
  overflow: hidden;
}

.placeholder-header {
  padding: 20px 24px;
  background: linear-gradient(180deg, rgba(16, 178, 225, 0.1), rgba(20, 24, 36, 0));
  border-bottom: 1px solid rgba(16, 178, 225, 0.1);
}

.placeholder-header h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-icon {
  font-size: 16px;
  color: rgba(16, 178, 225, 0.8);
  margin-right: 8px;
}

.booking-section {
  padding: 24px;
}

.section-label {
  display: block;
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}

.booking-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.booking-button {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(16, 178, 225, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgba(16, 178, 225, 0.05);
  display: flex;
  align-items: center;
  gap: 16px;
  text-align: left;
}

.button-icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex-shrink: 0;
}

.button-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.button-label {
  font-size: 15px;
  font-weight: 500;
}

.button-description {
  font-size: 13px;
}

.booking-button.clinic {
  background: linear-gradient(135deg, rgba(16, 178, 225, 0.15), rgba(28, 100, 242, 0.15));
  border: 1px solid rgba(16, 178, 225, 0.2);
}

.booking-button.clinic .button-icon {
  background: rgba(16, 178, 225, 0.2);
  color: #10b2e1;
}

.booking-button.virtual {
  background: linear-gradient(135deg, rgba(255, 59, 154, 0.15), rgba(255, 59, 154, 0.15));
  border: 1px solid rgba(255, 59, 154, 0.2);
}

.booking-button.virtual .button-icon {
  background: rgba(255, 59, 154, 0.2);
  color: #ff3b9a;
}

.booking-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.booking-button.clinic:hover {
  background: linear-gradient(135deg, rgba(16, 178, 225, 0.2), rgba(28, 100, 242, 0.2));
}

.booking-button.virtual:hover {
  background: linear-gradient(135deg, rgba(255, 59, 154, 0.2), rgba(255, 59, 154, 0.2));
}

.booking-button:active {
  transform: scale(0.98);
}
