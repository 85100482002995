.taskItem {
  padding: 12px !important;
  margin-bottom: 8px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.taskItem:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.taskContent {
  flex: 1;
  min-width: 0;
  margin-right: 16px;
}

.taskMain {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.taskHeader {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.typeTag, .statusTag {
  margin: 0;
}

.taskDescription {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.taskText {
  display: block;
  word-break: break-word;
}

.taskMeta {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.taskMetaGroup {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.taskDate, .taskPriority, .taskCreator, .taskResponsible {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.taskActions {
  display: flex;
  align-items: center;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.taskItem:hover .taskActions {
  opacity: 1;
}

.taskActions button {
  padding: 4px 8px;
  height: 32px;
  border-radius: 4px;
}

.completeButton {
  color: #52c41a;
}

.completeButton:hover {
  background: #f6ffed;
  color: #389e0d;
}

.editButton {
  color: #1890ff;
}

.editButton:hover {
  background: #e6f7ff;
  color: #096dd9;
}

.taskActions button[type="text"].ant-btn-dangerous {
  color: #ff4d4f;
}

.taskActions button[type="text"].ant-btn-dangerous:hover {
  background: #fff1f0;
  color: #cf1322;
}

.completed {
  background: #f6ffed;
  border-color: #b7eb8f;
}

.completed .taskDescription {
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.45);
}

/* Status-based styles */
.pending {
  border-left: 3px solid #faad14;
}

.in_progress {
  border-left: 3px solid #1890ff;
}

.completed {
  border-left: 3px solid #52c41a;
}

.overdue {
  border-left: 3px solid #f5222d;
}

.typeTag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
}

.typeTag :global(.anticon) {
  font-size: 14px;
}

.statusTag {
  font-size: 12px;
  padding: 0 8px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  height: 20px;
  font-weight: 500;
}

.taskText {
  font-size: 14px;
  color: #262626;
  font-weight: 500;
  line-height: 1.5;
}

.taskMeta {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;
  color: #8c8c8c;
  margin-top: 4px;
}

.taskResponsible {
  color: #1890ff;
} 