/* Modal Base Styling */
.task-input-modal .ant-modal-content {
  border: 1px solid rgba(16, 178, 225, 0.25) !important;
  border-radius: 16px !important;
  overflow: hidden !important;
}

.task-input-modal .ant-modal-header {
  background: #141824 !important;
  border-bottom: 1px solid rgba(16, 178, 225, 0.25) !important;
  padding: 16px 20px !important;
}

.task-input-modal .modal-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.task-input-modal .modal-title span {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.task-input-modal .modal-title svg {
  color: #10b2e1;
  font-size: 18px;
  filter: drop-shadow(0 0 6px rgba(16, 178, 225, 0.3));
}

/* Modal Content */
.task-input-modal .modal-content {
  padding: 20px;
}

.task-input-modal .task-context {
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  line-height: 1.5;
}

/* Input Section Styling */
.task-input-modal .input-section {
  background: rgba(16, 178, 225, 0.05);
  border: 1.5px solid rgba(16, 178, 225, 0.25);
  border-radius: 12px;
  padding: 20px;
}

.task-input-modal .input-row {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.task-input-modal .input-group {
  flex: 1;
}

.task-input-modal .input-group label {
  display: block;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 14px;
}

.task-input-modal .input-context {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  margin-top: 12px;
  line-height: 1.4;
}

/* Input Elements */
.task-input-modal .metric-input {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1.5px solid rgba(16, 178, 225, 0.25) !important;
  border-radius: 8px !important;
  color: #fff !important;
  height: 40px !important;
  padding: 8px 12px !important;
  transition: all 0.2s ease !important;
}

.task-input-modal .metric-input:hover {
  border-color: rgba(16, 178, 225, 0.4) !important;
}

.task-input-modal .metric-input:focus {
  border-color: #10b2e1 !important;
  box-shadow: 0 0 0 2px rgba(16, 178, 225, 0.2) !important;
}

/* Meal Logging Specific */
.task-input-modal .meal-modal-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.task-input-modal .meal-input-header {
  background: linear-gradient(to right, rgba(16, 178, 225, 0.1), rgba(16, 178, 225, 0.05));
  border-radius: 12px;
  padding: 16px;
  border: 1px solid rgba(16, 178, 225, 0.25);
}

.task-input-modal .cookbook-suggestion {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.task-input-modal .cookbook-icon {
  color: #10b2e1;
  filter: drop-shadow(0 0 6px rgba(16, 178, 225, 0.3));
}

.task-input-modal .cookbook-text {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 14px;
}

.task-input-modal .recipe-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.task-input-modal .recipe-chips .ant-tag {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.25) !important;
  color: #10b2e1 !important;
  padding: 6px 12px !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
}

.task-input-modal .recipe-chips .ant-tag:hover {
  background: rgba(16, 178, 225, 0.15) !important;
  border-color: rgba(16, 178, 225, 0.4) !important;
  transform: translateY(-1px);
}

/* Photo Upload Section */
.task-input-modal .photo-upload-section {
  margin-top: 20px;
}

.task-input-modal .take-photo-btn {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1.5px solid rgba(16, 178, 225, 0.25) !important;
  color: #10b2e1 !important;
  height: 44px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
}

.task-input-modal .take-photo-btn:hover {
  background: rgba(16, 178, 225, 0.15) !important;
  border-color: rgba(16, 178, 225, 0.4) !important;
  transform: translateY(-1px);
}

/* Modal Footer */
.task-input-modal .ant-modal-footer {
  background: #141824 !important;
  border-top: 1px solid rgba(16, 178, 225, 0.25) !important;
  padding: 16px 20px !important;
}

.task-input-modal .modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.task-input-modal .modal-footer .ant-btn {
  height: 40px !important;
  padding: 0 20px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
}

/* Primary (Complete) Button */
.task-input-modal .modal-footer .ant-btn-primary {
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1.5px solid rgba(16, 178, 225, 0.25) !important;
  color: #10b2e1 !important;
}

.task-input-modal .modal-footer .ant-btn-primary:hover {
  background: rgba(16, 178, 225, 0.15) !important;
  border-color: rgba(16, 178, 225, 0.4) !important;
  transform: translateY(-1px);
}

/* Cancel Button */
.task-input-modal .modal-footer .ant-btn-default {
  background: rgba(233, 30, 99, 0.1) !important;
  border: 1.5px solid rgba(233, 30, 99, 0.25) !important;
  color: #e91e63 !important;
}

.task-input-modal .modal-footer .ant-btn-default:hover {
  background: rgba(233, 30, 99, 0.15) !important;
  border-color: rgba(233, 30, 99, 0.4) !important;
  transform: translateY(-1px);
}
