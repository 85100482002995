.lab-tests {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.overview-card {
  background: rgba(16, 178, 225, 0.05);
  border: 1px solid rgba(16, 178, 225, 0.1);
  border-radius: 12px;
  padding: 16px;
}

.overview-card h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 8px;
  text-align: center;
}

.description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: center;
  margin: 0;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff !important;
}

.frequency {
  font-size: 16px;
  text-transform: capitalize;
  color: #fff !important;
}

.test-count {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7) !important;
}

.test-grid {
  display: grid;
  gap: 12px;
}

.test-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: #fff;
}

.test-item svg {
  color: #10b2e1;
  font-size: 16px;
  width: 20px;
}

.test-name {
  flex: 1;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
}

.category-tag {
  font-size: 11px;
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: capitalize;
  background: rgba(16, 178, 225, 0.1) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  color: #fff !important;
}

/* Category tag colors */
.category-tag.blood {
  background: rgba(231, 76, 60, 0.15) !important;
  border-color: rgba(231, 76, 60, 0.3) !important;
}

.category-tag.heart {
  background: rgba(52, 152, 219, 0.15) !important;
  border-color: rgba(52, 152, 219, 0.3) !important;
}

.category-tag.organs {
  background: rgba(46, 204, 113, 0.15) !important;
  border-color: rgba(46, 204, 113, 0.3) !important;
}

.category-tag.hormones {
  background: rgba(155, 89, 182, 0.15) !important;
  border-color: rgba(155, 89, 182, 0.3) !important;
}

.category-tag.vitamins {
  background: rgba(241, 196, 15, 0.15) !important;
  border-color: rgba(241, 196, 15, 0.3) !important;
}

.category-tag.general {
  background: rgba(149, 165, 166, 0.15) !important;
  border-color: rgba(149, 165, 166, 0.3) !important;
}

.category-tag.other {
  background: rgba(189, 195, 199, 0.15) !important;
  border-color: rgba(189, 195, 199, 0.3) !important;
}
