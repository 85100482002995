.processing-payment-container {
  min-height: calc(100vh - 120px);
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.processing-payment-container .processing-card {
  background: rgba(20, 24, 36, 0.95);
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 12px;
  padding: 32px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  margin-top: -180px;
}

.processing-payment-container .ant-spin {
  margin-bottom: 24px;
}

.processing-payment-container .anticon {
  font-size: 40px !important;
  color: #10b2e1 !important;
}

.processing-payment-container h2 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}

.processing-payment-container p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  margin-bottom: 24px;
}

.processing-payment-container .transaction-info {
  background: rgba(16, 178, 225, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-top: 24px;
}

.processing-payment-container .transaction-info small {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-family: monospace;
}

/* Prevent interaction while processing */
.processing-payment-container .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  backdrop-filter: blur(4px);
}

/* Add spinning logo styles */
.processing-payment-container .logo-spinner {
  width: 60px;
  height: 60px;
  margin: 0 auto 24px;
  animation: spin 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.processing-payment-container .logo-spinner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Remove default spin icon styles */
.processing-payment-container .ant-spin {
  display: none;
}
