.caresync-container .ant-tabs-content .cs-result-detail {
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0 16px;
}

.caresync-container .ant-tabs-content .cs-result-detail__content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.cs-result-detail__section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 100%;
  padding: 0 4px;
}

/* Responsive container for cards */
.cs-result-detail__card-container {
  width: 100%;
  min-width: 0; /* Prevents flex items from overflowing */
  max-width: 100%;
  padding: 0 4px;
}

.cs-result-detail__back {
  padding: 16px 4px 8px 4px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 8px;
  width: 100%;
}

.cs-result-detail__back-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 4px;
  height: 32px;
}

.cs-result-detail__back-btn:hover {
  color: #4cc9f0 !important;
  background: rgba(76, 201, 240, 0.1) !important;
}

.cs-result-detail__header-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 4px;
}

.cs-result-detail__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

.cs-result-detail__date {
  font-size: 14px;
  opacity: 0.8;
}

/* Info section styling */
.cs-result-detail__info-section {
  padding: 16px;
}

.cs-result-detail__info-section .ant-typography {
  margin-bottom: 16px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .caresync-container .ant-tabs-content .cs-result-detail {
    padding: 0 12px;
  }
  
  .cs-result-detail__card-container {
    padding: 0;
  }
  
  .cs-result-detail__info-section {
    padding: 12px;
  }
}
