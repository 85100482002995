.appointmentCard {
  border-radius: 12px;
  background: #ffffff;
  color: #141824;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 12px;
}

.calendarIcon {
  font-size: 20px;
  color: #65d2d8;
}

.cardTitle {
  font-size: 18px;
  color: #141824;
  margin: 0;
  font-weight: 500;
}

.dateDisplay {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

/* Date Selector */
.dateSelector {
  display: flex;
  gap: 8px;
  padding: 16px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.dateSelector::-webkit-scrollbar {
  display: none;
}

.dateItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 45px;
  padding: 8px 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgba(0, 0, 0, 0.02);
}

.dateItem:hover {
  background: rgba(101, 210, 216, 0.1);
}

.dateItem.selected {
  background: #65d2d8;
  color: #ffffff;
}

.dateItem.today {
  border: 1px solid #65d2d8;
}

.dayName {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 4px;
}

.dayNumber {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.selected .dayName,
.selected .dayNumber {
  color: #ffffff;
}

/* Timeline */
.timeline {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.appointmentSlot {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.timeColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}

.time {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 4px;
}

.timeConnector {
  width: 2px;
  height: 24px;
  background: rgba(0, 0, 0, 0.06);
  margin: 4px 0;
}

.appointmentContent {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(101, 210, 216, 0.05);
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 1px solid rgba(101, 210, 216, 0.1);
}

.appointmentContent:hover {
  background: rgba(101, 210, 216, 0.1);
  transform: translateX(4px);
}

.patientAvatar {
  background: linear-gradient(135deg, #65d2d8 0%, #4cc9f0 100%);
  color: #ffffff;
}

.appointmentInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.patientName {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.duration {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.breakSlot {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.breakContent {
  flex: 1;
  padding: 12px;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.02),
    rgba(0, 0, 0, 0.02) 10px,
    rgba(0, 0, 0, 0.04) 10px,
    rgba(0, 0, 0, 0.04) 20px
  );
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  text-align: center;
  transition: all 0.2s ease;
}

.breakContent:hover {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.04),
    rgba(0, 0, 0, 0.04) 10px,
    rgba(0, 0, 0, 0.06) 10px,
    rgba(0, 0, 0, 0.06) 20px
  );
}

.dailyScheduleCard {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.status {
  font-size: 12px;
  color: #666;
  margin-left: 8px;
}
