.photo-tips-container {
  position: fixed;
  top: 76px;
  left: 0;
  right: 0;
  padding: 16px;
  z-index: 90;
}

.photo-tip-card {
  border: 1px solid rgba(16, 178, 225, 0.15) !important;
  border-radius: 16px !important;
  padding: 24px !important;
}

/* Fixed height container for consistent arrow positioning */
.tip-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 220px; /* Increased from 180px */
  position: relative;
}

.tip-main {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-height: 220px; /* Increased to match container */
  overflow: hidden;
  padding: 16px 0; /* Added padding for better spacing */
}

/* Position arrows absolutely relative to container */
.navigation-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(16, 178, 225, 0.8);
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  font-size: 20px;
  background: transparent;
  z-index: 2;
}

.navigation-button.left {
  left: 0;
}

.navigation-button.right {
  right: 0;
}

.navigation-button:hover {
  opacity: 1;
}

/* Content styling */
.tip-icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(16, 178, 225, 0.1);
  color: #10b2e1;
  font-size: 32px;
  flex-shrink: 0;
  margin-bottom: 8px; /* Added margin for better spacing */
}

.tip-main h3 {
  margin: 0 0 8px 0; /* Added bottom margin */
  font-size: 22px;
  font-weight: 500;
  line-height: 1.2;
}

.tip-main p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 48px;
  max-width: 100%; /* Ensure text doesn't overflow */
}

.progress-dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 24px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(16, 178, 225, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  background: #10b2e1;
  transform: scale(1.2);
}

.swipe-indicator {
  display: none;
}

/* Animations */
.camera-tilt {
  animation: tilt 2s infinite;
}

.light-pulse {
  animation: pulse 2s infinite;
}

.frame-zoom {
  animation: zoom 2s infinite;
}

.measure-bounce {
  animation: measureBounce 2s infinite;
}

.clock-spin {
  animation: clockSpin 4s linear infinite;
}

.background-fade {
  animation: backgroundFade 2s infinite;
}

.leaf-float {
  animation: leafFloat 3s ease-in-out infinite;
}

@keyframes tilt {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-15deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes measureBounce {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.2);
  }
}

@keyframes clockSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes backgroundFade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@keyframes leafFloat {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-5px) rotate(5deg);
  }
}
