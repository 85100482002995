/* Media query for medium screens */
@media screen and (max-width: 1000px) {
  .responsive-modal-macros {
    width: 100% !important; /* Adjust the width for medium screens */
  }
}

/* Media query for extra large screens */
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .responsive-modal-macros {
    width: 80% !important; /* Adjust the width for extra large screens */
  }
}

/* Media query for extra large screens */
@media screen and (min-width: 1200px) {
  .responsive-modal-macros {
    width: 40% !important; /* Adjust the width for extra large screens */
  }
}
