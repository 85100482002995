.card-payment-container {
  min-height: calc(100vh - 120px);
  padding: 24px 16px;
  box-sizing: border-box;
}

.card-payment-container .payment-header {
  margin-bottom: 32px;
  text-align: center;
}

.card-payment-container .payment-header h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 600;
}

.card-payment-container .card-form {
  max-width: 400px;
  margin: 0 auto;
}

.card-payment-container .virtual-card {
  background: linear-gradient(135deg, rgba(20, 24, 36, 0.95), rgba(0, 0, 0, 0.9));
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 32px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), inset 0 0 32px rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
  height: 220px;
  width: 100%;
  aspect-ratio: 1.586 / 1; /* Standard card ratio */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-payment-container .virtual-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    transparent 0%,
    rgba(255, 255, 255, 0.05) 15%,
    rgba(255, 255, 255, 0.1) 30%,
    rgba(255, 255, 255, 0.05) 45%,
    transparent 60%
  );
  pointer-events: none;
}

.card-payment-container .card-chip {
  width: 45px;
  height: 35px;
  background: none;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.card-payment-container .card-chip img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-payment-container .card-chip::before {
  display: none;
}

.card-payment-container .card-number {
  color: rgba(255, 255, 255, 0.95);
  font-size: 22px;
  letter-spacing: 0.5px;
  font-family: 'Courier New', monospace;
  text-shadow: none;
  background: none;
  position: relative;
  z-index: 2;
  margin-bottom: 12px;
  padding-right: 8px;
  word-spacing: 6px;
}

.card-payment-container .card-number::before {
  display: none;
}

.card-payment-container .card-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
  padding-bottom: 6px;
}

.card-payment-container .card-holder {
  text-align: left;
  flex: 1;
  margin-right: 40px;
}

.card-payment-container .card-expires {
  text-align: left;
  min-width: 90px;
}

.card-payment-container .card-validity {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin-bottom: 4px;
  text-align: left;
  display: block;
}

.card-payment-container .card-details span {
  text-shadow: none;
  background: none;
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 1.2;
}

.card-payment-container .card-details::before {
  display: none;
}

.card-payment-container .form-section {
  background: #141824;
  border: 1px solid rgba(16, 178, 225, 0.15);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}

.card-payment-container .ant-form-item {
  margin-bottom: 24px;
}

.card-payment-container .ant-form-item-label > label {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 14px;
}

.card-payment-container .ant-input {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(16, 178, 225, 0.2) !important;
  border-radius: 8px !important;
  color: #fff !important;
  padding: 12px !important;
  height: auto !important;
  transition: all 0.3s ease !important;
}

.card-payment-container .ant-input:hover,
.card-payment-container .ant-input:focus {
  border-color: rgba(16, 178, 225, 0.5) !important;
  box-shadow: 0 0 0 2px rgba(16, 178, 225, 0.1) !important;
}

.card-payment-container .card-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.card-payment-container .submit-button {
  width: 80%;
  height: 48px;
  background: transparent !important;
  border: 1px solid #10b2e1 !important;
  border-radius: 8px !important;
  color: #10b2e1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 16px;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 0 10px rgba(16, 178, 225, 0.1);
}

.card-payment-container .submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(16, 178, 225, 0.2), 0 0 5px rgba(16, 178, 225, 0.1);
  border-color: rgba(16, 178, 225, 0.8) !important;
}

.card-payment-container .submit-button:active {
  transform: translateY(0);
  box-shadow: 0 0 20px rgba(16, 178, 225, 0.3), 0 0 10px rgba(16, 178, 225, 0.2);
}

.card-payment-container .submit-button.ant-btn-loading {
  opacity: 0.8;
  border-color: rgba(16, 178, 225, 0.5) !important;
}

.card-payment-container .submit-button .anticon,
.card-payment-container .submit-button svg {
  color: #10b2e1 !important;
}

.card-payment-container .back-button {
  background: transparent !important;
  border: 1px solid rgba(16, 178, 225, 0.3) !important;
  color: #10b2e1 !important;
  margin-right: 12px !important;
}

.card-payment-container .card-brand {
  position: absolute;
  bottom: 8px;
  right: 24px;
  font-size: 48px;
  color: #fff;
  z-index: 2;
}

.card-payment-container .card-validity {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin-bottom: 4px;
}

.card-payment-container .card-flip-container {
  perspective: 1000px;
  margin-bottom: 32px;
}

.card-payment-container .card-flipper {
  position: relative;
  width: 100%;
  height: 220px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-payment-container .card-flipper.flipped {
  transform: rotateY(180deg);
}

.card-payment-container .virtual-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background: linear-gradient(135deg, rgba(20, 24, 36, 0.95), rgba(0, 0, 0, 0.9));
  border-radius: 16px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), inset 0 0 32px rgba(255, 255, 255, 0.05);
}

.card-payment-container .magnetic-stripe {
  background: rgba(0, 0, 0, 0.7);
  height: 40px;
  margin: 20px 0;
  width: 100%;
}

.card-payment-container .cvv-panel {
  position: absolute;
  bottom: 24px;
  left: 24px;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 12px;
  border-radius: 4px;
  width: 60px;
  text-align: center;
}

.card-payment-container .cvv-label {
  position: absolute;
  top: -20px;
  left: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 10px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.card-payment-container .cvv-number {
  color: #fff;
  font-family: monospace;
  letter-spacing: 2px;
}

/* Add security message styling */
.card-payment-container .security-message {
  color: rgba(255, 255, 255, 0.7);
  font-size: 11px;
  text-align: left;
  margin: 12px 0;
  padding: 0 24px;
  font-style: italic;
}
